import parent from '@/views/Layout/parent.vue'
let router={};
router.data=[
    {
    path: '/embed/dept',
    redirect: '/embed/dept/list',
    name: 'dept-embed-show',
    component:parent,
    meta: {
        title: "部门表"
        },
    children: [
        {
        path: '/embed/dept/list',
        name: 'dept-embed-list',
        component: () => import("@/views/embed/dept/list.vue"),
        meta: {
            title: "部门表列表"
            }
        },
        {
        path: '/embed/dept/save/:id',
        name: 'dept-embed-save',
        component: () => import("@/views/embed/dept/save.vue"),
        meta: {
            title: "部门表编辑"
            }
        },
        {
        path: '/embed/dept/save',
        name: 'dept-embed-new',
        component: () => import("@/views/embed/dept/save.vue"),
        meta: {
            title: "部门表编辑"
            }
        },
    ]
    },
    {
    path: '/embed/initCover',
    redirect: '/embed/initCover/list',
    name: 'initCover-embed-show',
    component:parent,
    meta: {
        title: "启动页封面"
        },
    children: [
        {
        path: '/embed/initCover/list',
        name: 'initCover-embed-list',
        component: () => import("@/views/embed/initCover/list.vue"),
        meta: {
            title: "启动页封面列表"
            }
        },
        {
        path: '/embed/initCover/save/:id',
        name: 'initCover-embed-save',
        component: () => import("@/views/embed/initCover/save.vue"),
        meta: {
            title: "启动页封面编辑"
            }
        },
        {
        path: '/embed/initCover/save',
        name: 'initCover-embed-new',
        component: () => import("@/views/embed/initCover/save.vue"),
        meta: {
            title: "启动页封面编辑"
            }
        },
    ]
    },
    {
    path: '/embed/user',
    redirect: '/embed/user/list',
    name: 'user-embed-show',
    component:parent,
    meta: {
        title: "用户"
        },
    children: [
        {
        path: '/embed/user/list',
        name: 'user-embed-list',
        component: () => import("@/views/embed/user/list.vue"),
        meta: {
            title: "用户列表"
            }
        },
        {
        path: '/embed/user/save/:id',
        name: 'user-embed-save',
        component: () => import("@/views/embed/user/save.vue"),
        meta: {
            title: "用户编辑"
            }
        },
        {
        path: '/embed/user/save',
        name: 'user-embed-new',
        component: () => import("@/views/embed/user/save.vue"),
        meta: {
            title: "用户编辑"
            }
        },
    ]
    },
    {
    path: '/embed/role',
    redirect: '/embed/role/list',
    name: 'role-embed-show',
    component:parent,
    meta: {
        title: "角色"
        },
    children: [
        {
        path: '/embed/role/list',
        name: 'role-embed-list',
        component: () => import("@/views/embed/role/list.vue"),
        meta: {
            title: "角色列表"
            }
        },
        {
        path: '/embed/role/save/:id',
        name: 'role-embed-save',
        component: () => import("@/views/embed/role/save.vue"),
        meta: {
            title: "角色编辑"
            }
        },
        {
        path: '/embed/role/save',
        name: 'role-embed-new',
        component: () => import("@/views/embed/role/save.vue"),
        meta: {
            title: "角色编辑"
            }
        },
    ]
    },
    {
    path: '/embed/appletConfig',
    redirect: '/embed/appletConfig/list',
    name: 'appletConfig-embed-show',
    component:parent,
    meta: {
        title: "小程序系统配置"
        },
    children: [
        {
        path: '/embed/appletConfig/list',
        name: 'appletConfig-embed-list',
        component: () => import("@/views/embed/appletConfig/list.vue"),
        meta: {
            title: "小程序系统配置列表"
            }
        },
        {
        path: '/embed/appletConfig/save/:id',
        name: 'appletConfig-embed-save',
        component: () => import("@/views/embed/appletConfig/save.vue"),
        meta: {
            title: "小程序系统配置编辑"
            }
        },
        {
        path: '/embed/appletConfig/save',
        name: 'appletConfig-embed-new',
        component: () => import("@/views/embed/appletConfig/save.vue"),
        meta: {
            title: "小程序系统配置编辑"
            }
        },
    ]
    },
    {
    path: '/embed/userWallet',
    redirect: '/embed/userWallet/list',
    name: 'userWallet-embed-show',
    component:parent,
    meta: {
        title: "用户钱包表"
        },
    children: [
        {
        path: '/embed/userWallet/list',
        name: 'userWallet-embed-list',
        component: () => import("@/views/embed/userWallet/list.vue"),
        meta: {
            title: "用户钱包表列表"
            }
        },
        {
        path: '/embed/userWallet/save/:id',
        name: 'userWallet-embed-save',
        component: () => import("@/views/embed/userWallet/save.vue"),
        meta: {
            title: "用户钱包表编辑"
            }
        },
        {
        path: '/embed/userWallet/save',
        name: 'userWallet-embed-new',
        component: () => import("@/views/embed/userWallet/save.vue"),
        meta: {
            title: "用户钱包表编辑"
            }
        },
    ]
    },
    {
    path: '/embed/servicePod',
    redirect: '/embed/servicePod/list',
    name: 'servicePod-embed-show',
    component:parent,
    meta: {
        title: "运营单元表"
        },
    children: [
        {
        path: '/embed/servicePod/list',
        name: 'servicePod-embed-list',
        component: () => import("@/views/embed/servicePod/list.vue"),
        meta: {
            title: "运营单元表列表"
            }
        },
        {
        path: '/embed/servicePod/save/:id',
        name: 'servicePod-embed-save',
        component: () => import("@/views/embed/servicePod/save.vue"),
        meta: {
            title: "运营单元表编辑"
            }
        },
        {
        path: '/embed/servicePod/save',
        name: 'servicePod-embed-new',
        component: () => import("@/views/embed/servicePod/save.vue"),
        meta: {
            title: "运营单元表编辑"
            }
        },
    ]
    },
    {
    path: '/embed/scenic',
    redirect: '/embed/scenic/list',
    name: 'scenic-embed-show',
    component:parent,
    meta: {
        title: "景区"
        },
    children: [
        {
        path: '/embed/scenic/list',
        name: 'scenic-embed-list',
        component: () => import("@/views/embed/scenic/list.vue"),
        meta: {
            title: "景区列表"
            }
        },
        {
        path: '/embed/scenic/save/:id',
        name: 'scenic-embed-save',
        component: () => import("@/views/embed/scenic/save.vue"),
        meta: {
            title: "景区编辑"
            }
        },
        {
        path: '/embed/scenic/save',
        name: 'scenic-embed-new',
        component: () => import("@/views/embed/scenic/save.vue"),
        meta: {
            title: "景区编辑"
            }
        },
    ]
    },
    {
    path: '/embed/merchantsInfo',
    redirect: '/embed/merchantsInfo/list',
    name: 'merchantsInfo-embed-show',
    component:parent,
    meta: {
        title: "商户信息表"
        },
    children: [
        {
        path: '/embed/merchantsInfo/list',
        name: 'merchantsInfo-embed-list',
        component: () => import("@/views/embed/merchantsInfo/list.vue"),
        meta: {
            title: "商户信息表列表"
            }
        },
        {
        path: '/embed/merchantsInfo/save/:id',
        name: 'merchantsInfo-embed-save',
        component: () => import("@/views/embed/merchantsInfo/save.vue"),
        meta: {
            title: "商户信息表编辑"
            }
        },
        {
        path: '/embed/merchantsInfo/save',
        name: 'merchantsInfo-embed-new',
        component: () => import("@/views/embed/merchantsInfo/save.vue"),
        meta: {
            title: "商户信息表编辑"
            }
        },
    ]
    },
    {
    path: '/embed/shopInfo',
    redirect: '/embed/shopInfo/list',
    name: 'shopInfo-embed-show',
    component:parent,
    meta: {
        title: "商家信息"
        },
    children: [
        {
        path: '/embed/shopInfo/list',
        name: 'shopInfo-embed-list',
        component: () => import("@/views/embed/shopInfo/list.vue"),
        meta: {
            title: "商家信息列表"
            }
        },
        {
        path: '/embed/shopInfo/save/:id',
        name: 'shopInfo-embed-save',
        component: () => import("@/views/embed/shopInfo/save.vue"),
        meta: {
            title: "商家信息编辑"
            }
        },
        {
        path: '/embed/shopInfo/save',
        name: 'shopInfo-embed-new',
        component: () => import("@/views/embed/shopInfo/save.vue"),
        meta: {
            title: "商家信息编辑"
            }
        },
    ]
    },
    {
    path: '/embed/employeePermissionApply',
    redirect: '/embed/employeePermissionApply/list',
    name: 'employeePermissionApply-embed-show',
    component:parent,
    meta: {
        title: "员工权限申请"
        },
    children: [
        {
        path: '/embed/employeePermissionApply/list',
        name: 'employeePermissionApply-embed-list',
        component: () => import("@/views/embed/employeePermissionApply/list.vue"),
        meta: {
            title: "员工权限申请列表"
            }
        },
        {
        path: '/embed/employeePermissionApply/save/:id',
        name: 'employeePermissionApply-embed-save',
        component: () => import("@/views/embed/employeePermissionApply/save.vue"),
        meta: {
            title: "员工权限申请编辑"
            }
        },
        {
        path: '/embed/employeePermissionApply/save',
        name: 'employeePermissionApply-embed-new',
        component: () => import("@/views/embed/employeePermissionApply/save.vue"),
        meta: {
            title: "员工权限申请编辑"
            }
        },
    ]
    },
    {
    path: '/embed/company',
    redirect: '/embed/company/list',
    name: 'company-embed-show',
    component:parent,
    meta: {
        title: "公司企业表"
        },
    children: [
        {
        path: '/embed/company/list',
        name: 'company-embed-list',
        component: () => import("@/views/embed/company/list.vue"),
        meta: {
            title: "公司企业表列表"
            }
        },
        {
        path: '/embed/company/save/:id',
        name: 'company-embed-save',
        component: () => import("@/views/embed/company/save.vue"),
        meta: {
            title: "公司企业表编辑"
            }
        },
        {
        path: '/embed/company/save',
        name: 'company-embed-new',
        component: () => import("@/views/embed/company/save.vue"),
        meta: {
            title: "公司企业表编辑"
            }
        },
    ]
    },
    {
    path: '/embed/booking',
    redirect: '/embed/booking/list',
    name: 'booking-embed-show',
    component:parent,
    meta: {
        title: "预约表"
        },
    children: [
        {
        path: '/embed/booking/list',
        name: 'booking-embed-list',
        component: () => import("@/views/embed/booking/list.vue"),
        meta: {
            title: "预约表列表"
            }
        },
        {
        path: '/embed/booking/save/:id',
        name: 'booking-embed-save',
        component: () => import("@/views/embed/booking/save.vue"),
        meta: {
            title: "预约表编辑"
            }
        },
        {
        path: '/embed/booking/save',
        name: 'booking-embed-new',
        component: () => import("@/views/embed/booking/save.vue"),
        meta: {
            title: "预约表编辑"
            }
        },
    ]
    },
    {
    path: '/embed/trainVehicle',
    redirect: '/embed/trainVehicle/list',
    name: 'trainVehicle-embed-show',
    component:parent,
    meta: {
        title: "小火车车辆信息"
        },
    children: [
        {
        path: '/embed/trainVehicle/list',
        name: 'trainVehicle-embed-list',
        component: () => import("@/views/embed/trainVehicle/list.vue"),
        meta: {
            title: "小火车车辆信息列表"
            }
        },
        {
        path: '/embed/trainVehicle/save/:id',
        name: 'trainVehicle-embed-save',
        component: () => import("@/views/embed/trainVehicle/save.vue"),
        meta: {
            title: "小火车车辆信息编辑"
            }
        },
        {
        path: '/embed/trainVehicle/save',
        name: 'trainVehicle-embed-new',
        component: () => import("@/views/embed/trainVehicle/save.vue"),
        meta: {
            title: "小火车车辆信息编辑"
            }
        },
    ]
    },
    {
    path: '/embed/trainTicketConfig',
    redirect: '/embed/trainTicketConfig/list',
    name: 'trainTicketConfig-embed-show',
    component:parent,
    meta: {
        title: "小火车票配置"
        },
    children: [
        {
        path: '/embed/trainTicketConfig/list',
        name: 'trainTicketConfig-embed-list',
        component: () => import("@/views/embed/trainTicketConfig/list.vue"),
        meta: {
            title: "小火车票配置列表"
            }
        },
        {
        path: '/embed/trainTicketConfig/save/:id',
        name: 'trainTicketConfig-embed-save',
        component: () => import("@/views/embed/trainTicketConfig/save.vue"),
        meta: {
            title: "小火车票配置编辑"
            }
        },
        {
        path: '/embed/trainTicketConfig/save',
        name: 'trainTicketConfig-embed-new',
        component: () => import("@/views/embed/trainTicketConfig/save.vue"),
        meta: {
            title: "小火车票配置编辑"
            }
        },
    ]
    },
    {
    path: '/embed/reportYygd',
    redirect: '/embed/reportYygd/list',
    name: 'reportYygd-embed-show',
    component:parent,
    meta: {
        title: "异议工单台账"
        },
    children: [
        {
        path: '/embed/reportYygd/list',
        name: 'reportYygd-embed-list',
        component: () => import("@/views/embed/reportYygd/list.vue"),
        meta: {
            title: "异议工单台账列表"
            }
        },
    ]
    },
    {
    path: '/embed/reportSiteOrder',
    redirect: '/embed/reportSiteOrder/list',
    name: 'reportSiteOrder-embed-show',
    component:parent,
    meta: {
        title: "站点订单统计"
        },
    children: [
        {
        path: '/embed/reportSiteOrder/list',
        name: 'reportSiteOrder-embed-list',
        component: () => import("@/views/embed/reportSiteOrder/list.vue"),
        meta: {
            title: "站点订单统计列表"
            }
        },
    ]
    },
];

router.mergeArrayData=function (srcData) {
return [...router.data,...srcData];
}
export default router;