<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import demo from "@/views/wiki/wikipedia-h5.vue";
export default {
  components: {
    demo,
  },
  mounted() {
    // 使用this.$store.state.XXX可以直接访问到仓库中的状态
  },
  methods: {},
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
}
</style>
 