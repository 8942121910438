<template>
  <div class="right-header">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="tab.rs.name"
          :name="tab.rs.name"
          v-for="tab in allMenu"
          :key="tab.rs.id"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="layout">
      <div class="fold-tab">
        <div class="box" @click="chanageIcon()">
          <i :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
        </div>
        <div class="box" @click="$router.go(-1)">
          <i class="iconfont">&#xe75e;</i>
        </div>
        <div class="breadcrumb">
          <!-- <el-breadcrumb separator="/" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              :to="{ path: item.path }"
              v-for="(item, index) in breadList"
              :key="index"
            >
              {{ item.meta.title }}
            </el-breadcrumb-item>
          </el-breadcrumb> -->
        </div>
      </div>
      <div class="icon-layout">
<!--        <p-->
<!--          class="backold"-->
<!--          style="margin-right: 20px; cursor: pointer"-->
<!--          @click="navToOld"-->
<!--        >-->
<!--          回到旧版-->
<!--        </p>-->
        <el-dropdown
          trigger="click"
          v-if="permissionList.includes('erp:crm:working')"
        >
          <div class="punch">
            <div class="icon">
              <img src="@/assets/img/shangban@2x.png" class="img" alt="" />
            </div>
            <div class="oper">
              <div
                class="dot"
                :style="{
                  background: userInfo.workStatus === 1 ? '#00e169' : '#ADADAD',
                }"
              ></div>
              <p class="text">
                {{ userInfo.workStatus === 1 ? "我在上班" : "我已下班" }}
              </p>
              <div class="iconfont">&#xe60c;</div>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div class="dropdown-layout" @click="setWorkStatus(1)">
                <div class="dot" style="background-color: #00e169"></div>
                <div class="text">我在上班</div>
                <img
                  src="@/assets/img/get.png"
                  v-if="userInfo.workStatus === 1"
                  class="img"
                  alt=""
                />
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="dropdown-layout" @click="setWorkStatus(2)">
                <div class="dot"></div>
                <div class="text">我已下班</div>
                <img
                  src="@/assets/img/get.png"
                  v-if="userInfo.workStatus === 2"
                  class="img"
                  alt=""
                />
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <div class="iconfont icon-box">&#xe613;</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div class="popup-item">
                <img :src="userInfo.avatar" class="img" alt="" />
                <div>
                  <p>{{ userInfo.eName }}</p>
                  <p>{{ userInfo.dName }}</p>
                </div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <div class="popup-item" @click="logout">
                <i class="iconfont">&#xe609;</i>
                <p>退出登录</p>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div style="position: relative">
          <el-badge :value="unread" class="item" :hidden="unread <= 0">
            <div
              class="iconfont icon-box"
              @click="messageShow = !messageShow"
              style="margin-right: 0"
            >
              &#xe865;
            </div>
          </el-badge>
          <div class="message-layout" v-if="messageShow">
            <i class="iconfont" @click="messageShow = false">&#xe768;</i>
            <div class="message-item">
              <div class="title">消息通知</div>
            </div>
            <div class="item-layout" v-if="messageList.length > 0">
              <div
                class="message-item"
                style="padding-top: 25px"
                v-for="(msg, msgIndex) in messageList"
                :key="msgIndex"
                :style="{
                  borderBottom:
                    msgIndex + 1 === messageList.length ? 'none' : '',
                }"
              >
                <div class="left">
                  <div class="text_1">{{ msg.content.content }}</div>
                  <div class="text_2">{{ msg.content.dt | date }}</div>
                </div>
                <div class="right">
                  <div
                    class="btn"
                    v-if="msg.readStatus == 0"
                    @click="checkMessage(msg)"
                    v-loading.fullscreen.lock="fullscreenLoading"
                  >
                    查看
                  </div>
                  <div class="read" v-else>已读</div>
                </div>
              </div>
            </div>
            <div class="no-data" v-else>
              <img src="@/assets/img/wunwrong2.png" class="img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="route-tab" v-if="tags.length > 0">
		  <div
			class="tag"
			v-for="(tag, index) in tags"
			:key="index"
			@click="switchTag(index)"
		  >
			<div :class="index === tagActiveIndex ? 'title-active' : 'title'">
			  {{ tag.meta.title }}
			</div>
			<i
			  class="iconfont"
			  :class="index === tagActiveIndex ? 'icon-active' : 'icon'"
			  @click="closeTag(index)"
			  >&#xe768;</i
			>
		  </div>
		</div> -->
  </div>
</template>
<script>
import swei from "@/utils/common-utils";
function findObjectById(data, targetId) {
  // 遍历数据
  for (let i = 0; i < data.length; i++) {
    const currentObject = data[i].rs;

    // 检查当前对象是否具有目标 id
    if (currentObject.id === targetId) {
      return currentObject; // 找到匹配的对象，返回
    }

    // 如果当前对象有嵌套列表，则递归调用函数
    if (data[i].lst && data[i].lst.length > 0) {
      const nestedResult = findObjectById(data[i].lst, targetId);
      // 如果在嵌套列表中找到匹配的对象，则返回
      if (nestedResult) {
        return nestedResult;
      }
    }
  }

  // 如果未找到匹配的对象，返回 null
  return null;
}
export default {
  props: ["allMenu", "parentId"],
  data() {
    return {
      // 是否折叠
      isCollapse: false,
      breadList: [], //面包屑
      tags: [], //路由列表
      tagActiveIndex: 0,
      userInfo: {},
      messageList: [],
      messageShow: false,
      fullscreenLoading: false,
      unread: 0,
      timer_1: null,
      timer_2: null,
      permissionList: [],
      clockInReminders: [
        { hour: 9, minute: 55 },
        { hour: 10, minute: 5 },
        { hour: 22, minute: 0 },
      ],
      dateV: "",
      activeName: "",
    };
  },
  created() {
    this.getBreadcrumb();
    this.getEmployeeInfo();
    this.permissionList = this.$local.get("permissionList");
    if (this.permissionList == null) this.permissionList = [];
  },
  watch: {
    $route(to) {
      this.getBreadcrumb();
      // 监听路由变化，添加新标签
      if (!this.tags.some((tag) => tag.path === to.path)) {
        this.tags.push(to);
      }
      this.tags.some((tag, index) => {
        if (tag.path === to.path) {
          this.tagActiveIndex = index;
        }
      });
    },
    //监听获取路由
    allMenu(e) {
      console.log("触发子组件", this.parentId, this.allMenu);
      const foundObject = this.allMenu.find(
        (item) => item.rs.id == this.parentId
      );

      if (foundObject) {
        this.activeName = foundObject.rs.name;
      }
    },
    //路由父级id变化
    parentId(newValue, oldValue) {
      const foundObject = this.allMenu.find((item) => item.rs.id === newValue);
      if (foundObject) {
        this.activeName = foundObject.rs.name;
        console.log("id变化", foundObject.rs.name);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer_1);
    clearInterval(this.timer_2);
  },
  methods: {
    handleClick(e) {
      let activeMenu = this.allMenu[e.index];
      if (activeMenu.lst.length > 0) {
        //多级菜单
        this.jumpTo(
          activeMenu.lst[0].rs.url,
          activeMenu.lst[0].rs.id,
          activeMenu.rs.id
        );
      } else {
        //一级菜单
        this.jumpTo(activeMenu.rs.url, activeMenu.rs.id, activeMenu.rs.id);
      }
    },
    // 路由跳转
    jumpTo(url, id, parentId) {
      var indexOfHash = url.indexOf("#");
      if (url.includes("erp")) {
        var afterHash = url.slice(indexOfHash + 1);
        if (indexOfHash !== -1) {
          this.$router.push(afterHash + "?parentId=" + parentId);
        }
      } else {
        console.log("跳转iframe", url);
        this.$router.push({
          path: "/iframe/" + id,
          query: {
            src: swei.string.encode(url),
            // _t: timestamp,
            parentId: parentId,
          },
        });
      }
      this.$emit("childEvent", parentId);
    },
    navToOld() {
      window.open("/main.html");
    },
    //退出登录
    logout() {
      this.$local.clear();
      swei.common.ajax("/logout", {
        method: "POST",
        success: (res) => {
          location.href = "/erp/#/";
        },
        error:()=>
        {
          location.href="/";
        }
      });
    },
    // 查看消息
    checkMessage(e) {
      this.fullscreenLoading = true;
      swei.common.ajax(
        "/api/v1/workorder/objection/findByWorkorderNumberOrderNumber",
        {
          method: "GET",
          data: {
            workorderNumber: e.content.param.workorderNumber,
          },
          success: (data) => {
            this.updateMsgStatus(e.id);
            let orderNumber = data;
            this.messageShow = false;
            this.$router.push({
              path: "/iframe/10000",
              query: {
                src: "/jqt/?m=xiaoyou#/orderDataList?id=" + orderNumber,
              },
            });
          },
          error: (err) => {
            this.$message.error(err.message);
          },
        }
      );
    },
    //工单消息已读
    updateMsgStatus(id) {
      console.log("消息id", id);
      swei.common.ajax(
        "/api/v1/usr/basic/usermessage/updateMessageReadStatus",
        {
          method: "GET",
          data: {
            messageId: id,
          },
          success: (data) => {
            clearTimeout(this.timer_1);
            this.timer_1 = null;
            this.dateV = "";
            this.findAllUnreadWorkorderMessage();
          },
          error: (err) => {
            this.$message.error(err.message);
          },
        }
      );
    },
    // 获取工单消息
    findAllUnreadWorkorderMessage() {
      swei.common.ajax(
        "/api/v1/usr/basic/usermessage/findAllUnreadWorkorderMessage?dataVersion=" +
          this.dateV,
        {
          method: "GET",
          success: (data) => {
            if (data && data.length > 0) {
              data.forEach((item) => {
                item.content = JSON.parse(item.content);
              });
              this.messageList = data;
              this.unread = this.messageList.filter((item) => {
                console.log(item.readStatus);
                return item.readStatus == 0;
              }).length;
              this.dateV = data.reduce(
                (max, current) =>
                  current.pubTime > max.pubTime ? current : max,
                data[0]
              ).pubTime;
              console.log(this.dateV, "最大值");
            }
            this.timer_1 = setTimeout(() => {
              this.findAllUnreadWorkorderMessage();
            }, 5000);
          },
          error: (err) => {
            this.$message.error(err.message);
          },
        }
      );
    },
    // 打卡
    setWorkStatus(flag) {
      swei.common.ajax("/api/v1/erp/employee/setWorkStatus", {
        method: "POST",
        data: {
          workStatus: flag,
          userId: this.userInfo.userId,
        },
        success: (data) => {
          this.getEmployeeInfo();
          this.$message.success("打卡成功");
        },
        error: (err) => {
          this.$message.error(err.message);
        },
      });
    },
    // 获取个人信息
    getEmployeeInfo() {
      clearTimeout(this.timer_1);
      this.timer_1 = null;
      swei.common.ajax("/api/v1/usr/erp/employee/getEmployeeInfo", {
        method: "GET",
        success: (data) => {
          console.log("个人信息", data);
          this.userInfo = data;
          this.findAllUnreadWorkorderMessage();
          this.startClockInReminderTimer();
          if (data.eName === "客服部") {
            // 启动定时器检查是否需要提醒打卡
          }
        },
      });
    },
    startClockInReminderTimer() {
      // 每隔一分钟检查一次是否需要提醒打卡
      this.timer_2 = setInterval(() => {
        this.checkClockInReminder();
      }, 60000); // 一分钟检查一次
    },
    checkClockInReminder() {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      // console.log("打卡轮询");
      this.clockInReminders.forEach((reminder) => {
        if (
          currentHour === reminder.hour &&
          currentMinute === reminder.minute
        ) {
          this.showClockInReminder();
        }
      });
    },
    showClockInReminder() {
      this.$notify({
        title: "打卡提示",
        message: "请打卡！如果已打卡请忽略",
        duration: 0,
        type: "warning",
      });
      console.log("打卡时间到！");
    },
    switchTag(index) {
      // this.tagActiveIndex = index;
      // 切换标签，根据索引显示对应路由内容
      const tag = this.tags[index].path;
      this.$router.push({ path: tag });
    },
    closeTag(index) {
      // 关闭标签并退出页面
      const tag = this.tags[index].path;
      this.tags.splice(index, 1);
      if (this.tags.length > 0) {
        const previousTag = this.tags[this.tags.length - 1].path;
        this.$router.push(previousTag);
      } else {
        this.$router.push("/Layout/Layout");
      }
    },
    //面包屑处理
    getBreadcrumb() {
      // console.log(this.$route.matched, "面包屑");
      if (true) this.breadList = [];
      if (Object.keys(this.$route.matched[0].meta).length > 0) {
        this.breadList = this.$route.matched;
      } else {
        this.breadList = [];
      }
      // console.log(this.$route.matched, "面包屑。。。");
    },
    // 更改图标
    chanageIcon() {
      this.isCollapse = !this.isCollapse;
      // 往导航组件发送是否折叠的信息
      this.$bus.$emit("collapse", this.isCollapse);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  margin: 5px 20px 0px 20px;
}
::-webkit-scrollbar {
  width: 4px;
  /* 宽度 */
}

::-webkit-scrollbar-track {
  background: #d4daff;
  /* 轨道背景颜色 */
}

/* Thumb（滑块）*/
::-webkit-scrollbar-thumb {
  background: #555ef1;
  /* 滑块颜色 */
}

/* 滚动条圆角 */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  /* 滑块的圆角 */
}

.dropdown-layout {
  display: flex;
  align-items: center;
  width: 158px;
  height: 48px;
  border-radius: 10px;

  .text {
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #3d3d3d;
    margin: 0 15px 0 10px;
  }

  .dot {
    width: 12px;
    height: 12px;
    background: #adadad;
    border-radius: 50%;
  }

  .img {
    width: 12px;
    height: 8px;
  }
}

.popup-item {
  height: 178px;
  height: 60px;
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    margin-right: 8px;
  }

  p {
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #3d3d3d;
  }

  p:nth-of-type(2) {
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #a7a9ab;
  }
}

.right-header {
  width: 100%;
  height: 100%;
  margin-bottom: 6px;
  // background: #dee1e6;
  .layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0px;
    box-sizing: border-box;

    .fold-tab {
      margin-left: 24px;
      display: flex;
      align-items: center;

      .breadcrumb {
      }

      .box {
        cursor: pointer;
        margin-right: 24px;
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        box-shadow: 0px 5px 15px 0px rgba(34, 140, 244, 0.1);
        backdrop-filter: blur(20px);
        text-align: center;
        line-height: 32px;
      }
    }

    .icon-layout {
      display: flex;
      align-items: center;
      margin-right: 26px;

      .punch {
        position: relative;
        display: flex;
        align-items: center;
        width: 206px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        box-shadow: 0px 5px 15px 0px rgba(34, 140, 244, 0.1);
        backdrop-filter: blur(20px);
        padding: 0 20px 0 14px;
        box-sizing: border-box;
        margin-right: 20px;
        cursor: pointer;

        .icon {
          padding-right: 13px;
          box-sizing: border-box;
          border-right: 1px solid #e0eafe;

          .img {
            width: 20px;
            height: 20px;
          }
        }

        .oper {
          display: flex;
          align-items: center;
          margin-left: 12px;

          .text {
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            color: #3d3d3d;
            margin: 0 15px 0 10px;
          }

          .dot {
            width: 12px;
            height: 12px;
            background: #adadad;
            border-radius: 50%;
          }
        }
      }

      .icon-box {
        cursor: pointer;
        position: relative;
        color: #333333;
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        box-shadow: 0px 5px 15px 0px rgba(34, 140, 244, 0.1);
        backdrop-filter: blur(20px);
        line-height: 32px;
        text-align: center;
        margin-right: 20px;
      }

      .message-layout {
        position: absolute;
        top: 68px;
        right: 0;
        width: 520px;
        background: #f6faff;
        border: 1px solid #ffffff;
        border-radius: 10px;
        box-shadow: 0px 5px 15px 0px rgba(34, 140, 244, 0.1);
        backdrop-filter: blur(20px);
        padding: 17px 0px 0 0px;
        box-sizing: border-box;
        line-height: normal;

        .item-layout {
          max-height: 311px;
          overflow: auto;
          padding-bottom: 10px;
          box-sizing: border-box;
        }

        .no-data {
          .img {
            width: 200px;
            height: 200px;
          }
        }

        .iconfont {
          position: absolute;
          right: 10px;
          top: 10px;
          color: #828c98;
          font-size: 13px;
          cursor: pointer;
        }

        .message-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 19px 20px;
          box-sizing: border-box;
          border-bottom: 1px solid #e0eafe;

          .title {
            font-size: 16px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            color: #4e5969;
          }

          .left {
            .text_1 {
              width: 252px;
              font-size: 14px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
              font-weight: 400;
              color: #2a72ff;
              margin-bottom: 12px;
              text-align: left;
            }

            .text_2 {
              font-size: 12px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
              font-weight: 400;
              color: #828c98;
              text-align: left;
            }
          }

          .right {
            .btn {
              width: 72px;
              height: 28px;
              background: #e0eafe;
              border-radius: 14px;
              font-size: 14px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
              font-weight: 400;
              text-align: center;
              line-height: 28px;
              color: #1d69ff;
              cursor: pointer;
            }

            .read {
              font-size: 14px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
              font-weight: 400;
              color: #828c98;
              margin-right: 22px;
            }
          }
        }
      }

      .iconfont {
        font-size: 20px;
      }
    }
  }

  .route-tab {
    margin-top: 30px;
    width: 1616px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 10px;
    box-shadow: 0px 5px 15px 0px rgba(34, 140, 244, 0.1);
    backdrop-filter: blur(20px);
    margin-left: 24px;
    display: flex;
    align-items: center;

    .tag {
      height: 14px;
      display: flex;
      align-items: center;
      padding: 0 18px 0 30px;
      box-sizing: border-box;
      cursor: pointer;
      border-right: 1px solid #e0eafe;

      .title {
        margin-right: 24px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #4e5969;
      }

      .title-active {
        margin-right: 24px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #1d69ff;
        transform: 0.9s;
      }

      .icon {
        font-size: 12px;
        color: #828c98;
      }

      .icon-active {
        border-radius: 50%;
        color: #1d69ff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-row {
    padding: 0 20px;

    .el-col {
      height: 100px;
      line-height: 50px;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .el-dropdown {
        margin-right: 10px;
      }
    }
  }
}
</style>