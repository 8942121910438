<template>
  <div style="height: 100%">
    <el-menu
      :default-active="activeIndex"
      :collapse="collapsed"
      collapse-transition
      :router="true"
      class="el-menu-vertical-demo"
      background-color="#f2f8ff"
      active-background-color="#eaf1ff"
      text-color="#4E5969"
      active-text-color="#1D69FF"
      :unique-opened="true"
      ref="elmenu"
      @select="selectmenu"
    >
      <div class="logobox" @click="$router.replace('/menu')">
        <img
          class="logoimg"
          src="@/assets/img/login_logo-2.png"
          alt=""
          v-if="!collapsed"
        />
        <img
          style="width: 40px; height: 40px"
          src="@/assets/img/logo-s@2x.png"
          alt=""
          v-else
        />
      </div>
      <template v-for="item in allMenu">
        <el-submenu
          :index="item.rs.afterUrl"
          v-if="item.lst && item.lst.length > 0"
        >
          <template slot="title">
            <i :class="item.rs.icon"></i>
            <span style="margin-left: 10px">{{ item.rs.name }}</span>
          </template>
          <el-menu-item
            v-for="subItem in item.lst"
            :key="subItem.rs.id"
            :index="subItem.rs.afterUrl"
          >
            <i :class="subItem.rs.icon"></i>
            <span style="margin-left: 10px">{{ subItem.rs.name }}</span>
            <span style="color: red">{{ subItem.rs.url ? "" : "*" }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :index="item.rs.afterUrl">
          <i :class="item.rs.icon"></i>
          <span style="margin-left: 10px">{{ item.rs.name }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
import swei from "@/utils/common-utils";

export default {
  props: ["allMenu"],
  data() {
    return {
      collapsed: false,
      activeIndex: null,
    };
  },
  created() {
    this.activeIndex = this.$route.path;
  },
  mounted() {
    this.$bus.$on("collapse", (value) => {
      this.collapsed = value;
    });
    console.log(this.$store.state.menuId, "left菜单id");

    // 刷新的时候判断页面宽度的大小，做是否折叠的判断
    this.collapsed = document.body.clientWidth < 1000 ? true : false;
    // 托拉拽页面和 点击 浏览器放大缩小按钮的时候，做是否折叠的判断
    window.addEventListener("resize", () => {
      const width = document.body.clientWidth;
      this.collapsed = width < 1000 ? true : false;
    });
  },
  watch: {
    $route(to, from) {
      this.$refs.elmenu.activeIndex = to.path;
      console.log("路由变动", this.$refs.elmenu);
    },
  },
  methods: {
    selectmenu(index) {
      console.log(index, "菜单回调");
      this.activeIndex = index;
    },

    handleUrl(url, id) {
      var indexOfHash = url.indexOf("#");
      if (url.includes("erp")) {
        var afterHash = url.slice(indexOfHash + 1);
        if (indexOfHash !== -1) {
          return afterHash;
        }
      } else {
        return `/iframe/${id}?src=` + swei.string.encode(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* 滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 190px;
  text-align: left;
  height: 100%;
}
.el-menu-vertical-demo {
  text-align: left;
  height: 100%;
}
//
.el-menu-item.is-active {
  background-color: #e0eafe !important;
  color: #1d69ff !important;
}
.el-menu-item-group__title {
  padding: 0px;
}
.el-menu-bg {
  background-color: #1f2d3d !important;
}
.el-menu {
  border: none;
}
.logobox {
  cursor: pointer;
  line-height: 40px;
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px;
}
.logoimg {
  width: 140px;
  height: 40px;
}
</style>