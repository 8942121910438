import parent from '@/views/Layout/parent.vue'
let router={};
router.data=[
    {
    path: '/embed/sgOrderExtraSumData',
    redirect: '/sg/embed/sgOrderExtraSumData/list',
    name: 'sgOrderExtraSumData-embed-show',
    component:parent,
    meta: {
        title: "系统外运营单元扎帐记录"
        },
    children: [
        {
        path: '/sg/embed/sgOrderExtraSumData/list',
        name: 'sgOrderExtraSumData-embed-list',
        component: () => import("@/views/sg/embed/sgOrderExtraSumData/list.vue"),
        meta: {
            title: "系统外运营单元扎帐记录列表"
            }
        },
        {
        path: '/sg/embed/sgOrderExtraSumData/save/:id',
        name: 'sgOrderExtraSumData-embed-save',
        component: () => import("@/views/sg/embed/sgOrderExtraSumData/save.vue"),
        meta: {
            title: "系统外运营单元扎帐记录编辑"
            }
        },
        {
        path: '/sg/embed/sgOrderExtraSumData/save',
        name: 'sgOrderExtraSumData-embed-new',
        component: () => import("@/views/sg/embed/sgOrderExtraSumData/save.vue"),
        meta: {
            title: "系统外运营单元扎帐记录编辑"
            }
        },
    ]
    },
    {
    path: '/embed/fen',
    redirect: '/sg/embed/fen/list',
    name: 'fen-embed-show',
    component:parent,
    meta: {
        title: "围栏"
        },
    children: [
        {
        path: '/sg/embed/fen/list',
        name: 'fen-embed-list',
        component: () => import("@/views/sg/embed/fen/list.vue"),
        meta: {
            title: "围栏列表"
            }
        },
        {
        path: '/sg/embed/fen/save/:id',
        name: 'fen-embed-save',
        component: () => import("@/views/sg/embed/fen/save.vue"),
        meta: {
            title: "围栏编辑"
            }
        },
        {
        path: '/sg/embed/fen/save',
        name: 'fen-embed-new',
        component: () => import("@/views/sg/embed/fen/save.vue"),
        meta: {
            title: "围栏编辑"
            }
        },
    ]
    },
    {
    path: '/embed/scenic',
    redirect: '/sg/embed/scenic/list',
    name: 'scenic-embed-show',
    component:parent,
    meta: {
        title: "运营单元"
        },
    children: [
        {
        path: '/sg/embed/scenic/list',
        name: 'scenic-embed-list',
        component: () => import("@/views/sg/embed/scenic/list.vue"),
        meta: {
            title: "运营单元列表"
            }
        },
        {
        path: '/sg/embed/scenic/save/:id',
        name: 'scenic-embed-save',
        component: () => import("@/views/sg/embed/scenic/save.vue"),
        meta: {
            title: "运营单元编辑"
            }
        },
        {
        path: '/sg/embed/scenic/save',
        name: 'scenic-embed-new',
        component: () => import("@/views/sg/embed/scenic/save.vue"),
        meta: {
            title: "运营单元编辑"
            }
        },
    ]
    },
];

router.mergeArrayData=function (srcData) {
return [...router.data,...srcData];
}
export default router;