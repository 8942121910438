import Vue from 'vue'
import VueRouter from 'vue-router'
// 引入组件
import Login from '@/views/Login/login.vue'
import Layout from '@/views/Layout/Layout.vue'

// 引入本地存贮封装
// import local from '@/utils/local'

Vue.use(VueRouter)
// 解决跳转当前导航的警告
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

const routes = [
  //重定向
  // 登录
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/Layout/menu.vue')
  },
  //主页
  {
    path: '/Layout/Layout',
    component: Layout,
    name: 'layout',
    redirect: '/workOrder/flowList',
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/iframe/:id',
        name: 'iframe/:id',
        component: () => import("@/views/iframe/iframe.vue")
      },
      {
        path: '/scenicMgt/vehicleproductionInfo',
        name: 'vehicleproductionInfo',
        component: () => import('@/views/scenicMgt/vehicleproductionInfo.vue'),
        meta: {
          title: "车辆生产信息"
        }
      },
      {
        path: '/site/analysis',
        name: 'siteAnalysis',
        component: () => import('@/views/site/analysis.vue'),
        meta: {
          title: "站点分析"
        }
      },
      {
        path: '/scenicMgt/permissionAssignment',
        name: 'permissionAssignment',
        component: () => import('@/views/scenicMgt/permissionAssignment.vue'),
        meta: {
          title: "运营单元权限分配"
        }
      },
      {
        path: '/scenicMgt/addScenic',
        name: 'addScenic',
        component: () => import('@/views/scenicMgt/addScenic.vue'),
        meta: {
          title: "景区运营单元基础配置"
        }
      },
      {
        path: '/workOrder/flowList',
        name: 'flowList',
        component: () => import('@/views/workOrder/flowList.vue'),
        meta: {
          title: "执行流水列表"
        }
      }, {
        path: '/workOrder/realTimeStatistics',
        name: 'realTimeStatistics',
        component: () => import('@/views/workOrder/realTimeStatistics.vue'),
        meta: {
          title: "工单实时统计"
        }
      },
      {
        path: '/workOrder/staffStatusTracker',
        name: 'staffStatusTracker',
        component: () => import('@/views/workOrder/staffStatusTracker.vue'),
        meta: {
          title: "员工实时上下班情况"
        }
      },
      {
        path: '/workOrder/inspectionConfig',
        name: 'inspectionConfig',
        component: () => import('@/views/workOrder/inspectionConfig.vue'),
        meta: {
          title: '工单策略配置'
        }
      },
      {
        path: '/workOrder/workOrderList',
        name: 'workOrderList',
        component: () => import('@/views/workOrder/workOrderList.vue'),
        meta: {
          title: '工单列表'
        }
      },
      {
        path: '/shipCustomerService',
        name: 'shipCustomerService',
        component: () => import('@/views/boat/shipCustomerService.vue'),
        meta: {
          title: "订单管理"
        }
      },
      {
        path: '/shipList',
        name: 'shipList',
        component: () => import('@/views/boat/shipList.vue'),
        meta: {
          title: '船只列表'
        }
      },
      {
        path: '/invoiceList',
        name: 'invoiceList',
        component: () => import('@/views/boat/invoiceList.vue'),
        meta: {
          title: '开票列表'
        }
      },
      {
        path: '/wharfList',
        name: 'wharfList',
        component: () => import('@/views/boat/wharfList.vue'),
        meta: {
          title: "码头列表"
        }
      },
      {
        path: '/revenue',
        name: 'revenue',
        component: () => import('@/views/boat/revenue.vue'),
        meta: {
          title: "营收统计"
        }
      },
      {
        path: '/addPark',
        component: () => import('@/views/form/addPark.vue'),
        name: 'addPark',
        meta: {
          title: '新增公园'
        }
      }, {
        path: '/table',
        component: () => import('@/views/form/table.vue'),
        name: 'table', meta: {
          title: '公园/景区列表'
        }
      },
      {
        path: '/addScenic',
        component: () => import('@/views/form/addScenic.vue'),
        name: 'addScenic',
        meta: {
          title: '新增景区'
        }
      },
      {
        path: '/table-demo',
        component: () => import('@/views/table-demo.vue'),
        name: 'table-demo',
        meta: {
          title: "未命名"
        }
      },
      {
        path: '/overview',
        component: () => import('@/views/overview.vue'),
        name: 'overview',
        meta: {
          title: '工作台'
        }
      }
      ,
      {
        path: '/user-data',
        component: () => import('@/views/user-data.vue'),
        name: 'user-data',
        meta: {
          title: "平台用户数据"
        }
      }
      ,
      {
        path: '/newRevenueData',
        component: () => import('@/views/newRevenueData.vue'),
        name: 'newRevenueData',
        meta: {
          title: "营收统计"
        }
      },
      {
        path: '/revenue-data',
        component: () => import('@/views/revenue-data.vue'),
        name: 'revenue-data',
        meta: {
          title: "营收数据(旧版)"
        }
      }
      ,
      {
        path: '/merchant-data',
        component: () => import('@/views/merchant-data.vue'),
        name: 'merchant-data',
        meta: {
          title: "商户数据"
        }
      }
      ,
      {
        path: '/operational-data',
        component: () => import('@/views/operational-data.vue'),
        name: 'operational-data',
        meta: {
          title: "运营数据"
        }
      }
      ,
      {
        path: '/analog-data',
        component: () => import('@/views/analog-data.vue'),
        name: 'analog-data',
        meta: {
          title: "剧本演示控制"
        }
      },
      {
        path: '/dingdong/role-user',
        component: () => import('@/views/dingdong/role-user.vue'),
        name: 'role-user',
        meta: {
          title: "用户授权查看"
        }
      }
      ,
      {
        path: '/tools/calculation-table',
        component: () => import('@/views/tools/calculation-table.vue'),
        name: 'calculation-table',
        meta: {
          title: "未命名"
        }
      }
      ,
      {
        path: '/wiki/wikipedia',
        component: () => import('@/views/wiki/wikipedia.vue'),
        name: 'wikipedia',
        meta: {
          title: '小游知识库'
        }
      }
      ,
      {
        path: '/wiki/wiki-article',
        component: () => import('@/views/wiki/wiki-article.vue'),
        name: 'wiki-article',
        meta: {
          title: "文章详情"
        }
      }
      ,
      {
        path: '/wiki/wiki-publish',
        component: () => import('@/views/wiki/wiki-publish.vue'),
        name: 'wiki-publish',
        meta: {
          title: "文章编辑"
        }
      }
      ,
      {
        path: '/dailyReport',
        component: () => import('@/views/dailyReport.vue'),
        name: 'dailyReport',
        meta: {
          title: "每日营收查询"
        }
      },
      {
        path: '/wiki/tagsManager',
        component: () => import('@/views/wiki/tagsManager.vue'),
        name: 'tagsManager',
        meta: {
          title: "知识库标签管理"
        }
      },
      {
        path: '/wiki/typesManager',
        component: () => import('@/views/wiki/typesManager.vue'),
        name: 'typesManager',
        meta: {
          title: "知识库分类管理"
        }
      },
      {
        path: '/wiki/myArticle',
        component: () => import('@/views/wiki/myArticle.vue'),
        name: 'myArticle',
        meta: {
          title: "我的文章"
        }
      },
      {
        path: '/push/scenarioList',
        component: () => import('@/views/push/scenarioList.vue'),
        name: 'scenarioList',
        meta: {
          title: "推送场景配置"
        }
      },
      {
        path: '/push/scenarioDetail',
        component: () => import('@/views/push/scenarioDetail.vue'),
        name: 'scenarioDetail',
        meta: {
          title: "未命名"
        }
      },
      {
        path: '/push/pushTaskList',
        component: () => import('@/views/push/pushTaskList.vue'),
        name: 'pushTaskList',
        meta: {
          title: "推送任务列表"
        }
      },
      {
        path: '/tools/dev_createCheckWorkorder',
        component: () => import('@/views/tools/dev_createCheckWorkorder.vue'),
        name: 'dev_createCheckWorkorder',
        meta: {
          title: "手动工单(新)"
        }
      },
      {
        path: '/push/scenarioList',
        component: () => import('@/views/push/scenarioList.vue'),
        name: 'scenarioList',
        meta: {
          title: "推送场景配置"
        }
      },
      {
        path: '/push/scenarioDetail',
        component: () => import('@/views/push/scenarioDetail.vue'),
        name: 'scenarioDetail',
        meta: {
          title: "未命名"
        }
      },
      {
        path: '/push/pushTaskList',
        component: () => import('@/views/push/pushTaskList.vue'),
        name: 'pushTaskList',
        meta: {
          title: "推送任务列表"
        }
      },
      {
        path: '/workOrder/addWorkOrder',
        component: () => import('@/views/workOrder/addWorkOrder.vue'),
        name: 'addWorkOrder',
        meta: {
          title: "未命名"
        }
      },
      {
        path: '/workOrder/partUseRecordList',
        component: () => import('@/views/workOrder/partUseRecordList.vue'),
        name: 'partUseRecordList',
        meta: {
          title: "配件流水清单"
        }
      },
      {
        path: '/scenic/attractionsForm',
        component: () => import('@/views/scenic/attractionsForm.vue'),
        name: 'attractionsForm'
      },
      {
        path: '/workOrder/repairPartsList',
        component: () => import('@/views/workOrder/repairPartsList.vue'),
        name: 'repairPartsList',
        meta: {
          title: "配件列表"
        }
      },
      {
        path: '/scenic/scenicForm',
        component: () => import('@/views/scenic/scenicForm.vue'),
        name: 'scenicForm'
      },
      {
        path: '/scenic/attractionsForm',
        component: () => import('@/views/scenic/attractionsForm.vue'),
        name: 'attractionsForm'
      },
      {
        path: '/scenic/eventsForm',
        component: () => import('@/views/scenic/eventsForm.vue'),
        name: 'eventsForm'
      },
      {
        path: '/scenic/travelStrategyForm',
        component: () => import('@/views/scenic/travelStrategyForm.vue'),
        name: 'travelStrategyForm'
      },
      {
        path: '/workOrder/checkWorkOrderList',
        component: () => import('@/views/workOrder/checkWorkOrderList.vue'),
        name: 'checkWorkOrderList'
      },
      {
        path: '/scenic/qaForm',
        component: () => import('@/views/scenic/qaForm.vue'),
        name: 'QaForm'
      },
      {
        path: '/scenic/systemLinksForm',
        component: () => import('@/views/scenic/systemLinksForm.vue'),
        name: 'systemLinksForm'
      },
      {
        path: '/scenic/systemButtonForm',
        component: () => import('@/views/scenic/systemButtonForm.vue'),
        name: 'systemButtonForm'
      },
      {
        path: '/scenic/initCoverForm',
        component: () => import('@/views/scenic/initCoverForm.vue'),
        name: 'initCoverForm'
      },
      {
        path: '/scenic/scenicMediaResourceForm',
        component: () => import('@/views/scenic/scenicMediaResourceForm.vue'),
        name: 'scenicMediaResourceForm'
      },
      {
        path: '/scenic/scenicIndex',
        component: () => import('@/views/scenic/scenicIndex.vue'),
        name: 'scenicIndex'
      },
      {
        path: '/scenic/shopInfoForm',
        component: () => import('@/views/scenic/shopInfoForm.vue'),
        name: 'shopInfoForm'
      },
      {
        path: '/scenic/scenicList',
        component: () => import('@/views/scenic/scenicList.vue'),
        name: 'scenicList'
      },
      {
        path: '/scenic/attractionsList',
        component: () => import('@/views/scenic/attractionsList.vue'),
        name: 'attractionsList'
      },
      {
        path: '/scenic/scenicMediaResourceList',
        component: () => import('@/views/scenic/scenicMediaResourceList.vue'),
        name: 'scenicMediaResourceList'
      },
      {
        path: '/scenic/eventsList',
        component: () => import('@/views/scenic/eventsList.vue'),
        name: 'eventsList'
      },
      {
        path: '/scenic/systemButtonList',
        component: () => import('@/views/scenic/systemButtonList.vue'),
        name: 'systemButtonList'
      },
      {
        path: '/scenic/initCoverList',
        component: () => import('@/views/scenic/initCoverList.vue'),
        name: 'initCoverList'
      },
      {
        path: '/scenic/travelStrategyList',
        component: () => import('@/views/scenic/travelStrategyList.vue'),
        name: 'travelStrategyList'
      },
      {
        path: '/scenic/systemLinksList',
        component: () => import('@/views/scenic/systemLinksList.vue'),
        name: 'systemLinksList'
      },
      {
        path: '/scenic/shopInfoList',
        component: () => import('@/views/scenic/shopInfoList.vue'),
        name: 'shopInfoList'
      },
      {
        path: '/merchant/orderListReport',
        component: () => import('@/views/merchant/orderListReport.vue'),
        name: 'orderListReport',
        meta: {
          title: "营收统计"
        }
      },
      {
        path: '/camping/orderList',
        component: () => import('@/views/camping/orderList.vue'),
        meta: {
          title: '订单列表'
        }
      },
      {
        path: '/merchant/conformityOrderListReport',
        component: () => import('@/views/merchant/conformityOrderListReport.vue'),
        name: 'conformityOrderListReport',
        meta: {
          title: "营收统计"
        }
      },
      {
        path: '/merchant/hotelOrderListReport',
        component: () => import('@/views/merchant/hotelOrderListReport.vue'),
        name: 'hotelOrderListReport',
        meta: {
          title: "营收统计"
        }
      },
      {
        path: '/merchant/broadcastVoiceConfig',
        component: () => import('@/views/merchant/broadcastVoiceConfig.vue'),
        name: 'podVoiceConfig',
        meta: {
          title: "营收统计"
        }
      },
      {
        path: '/workOrder/orderDetail',
        component: () => import('@/views/workOrder/orderDetail.vue'),
        name: 'podVoiceConfig',
        meta: {
          title: "工单详情"
        }
      },
    ]
  },
  {
    path: '/wiki/wikipedia-h5',
    component: () => import('@/views/wiki/wikipedia-h5.vue'),
    name: 'wikipedia-h5',
    meta: {
      title: "未命名"
    }
  }
  ,
  {
    path: '/wiki/wiki-article-h5',
    component: () => import('@/views/wiki/wiki-article-h5.vue'),
    name: 'wiki-article-h5',
    meta: {
      title: "未命名"
    }
  }

]
// bike_status
routes[2].children.push({
    path: '/test/amap',
    component: () => import('@/test/amap.vue'),
    name: 'test-amap',
    meta: {
        title: "未命名"
    }
});
routes[2].children.push({
    path: '/test/drawMapArea',
    component: () => import('@/test/drawMapArea.vue'),
    name: 'draw-map-area',
    meta: {
        title: "未命名"
    }
});
import embedRouter from './index-embeds';
import sgEmbedRouter from './sg/index-embeds';

routes[2].children = embedRouter.mergeArrayData(routes[2].children);
routes[2].children = sgEmbedRouter.mergeArrayData(routes[2].children);
routes[2].children = [...routes[2].children, ...[{
  path: '/embed/user/list1',
  name: 'user',
  component: () => import("@/views/user-sample/list.vue"),
  meta: {
    title: "用户列表"
  }

}]];

const router = new VueRouter({
  routes
})

export default router