import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const persistedState = localStorage.getItem('parentMenuId');
const initialState = JSON.parse(persistedState) || {};
const store = new Vuex.Store({
  state: {
    menuId: initialState.menuId
  },
  getters: {
  },
  mutations: {
    //更新菜单id
    updataMenuId(state, newValue) {
      state.menuId = newValue
       // 在更新数据时，同时更新本地存储
       localStorage.setItem('parentMenuId', JSON.stringify({ menuId: newValue }));
    }
  },
  actions: {

  },
  modules: {
  }
});
export default store;