<template>
  <div>
    <el-card :body-style="{ padding: '0' }" class="box-card">
      <div class="body-z">
        <div class="user-publist">
          <div class="wiki-publish">
            <div
              class="wiki-user"
              :onclick="'parent.window.sw.open(\'个人中心\',\'/erp/?m=xiaoyou#/wiki/myArticle\')'"
            >
              <img :src="userInfo.avatar" alt="" class="avatar" />
              <div class="right">
                <div class="name-admin">
                  <div class="name">{{ userInfo.name }}</div>
                  <div class="admin" v-if="userInfo.managerView">
                    <span>主管</span>
                  </div>
                  <div
                    class="admin"
                    v-if="params.enableWikiAdmin"
                    style="background: linear-gradient(90deg, #fc984d, #ff3f4e)"
                  >
                    <span>超管</span>
                  </div>
                  <img
                    v-if="userInfo.wikiAdmin"
                    @click.stop="adminSwitch"
                    src="./img/qiehuan@2x.png"
                    alt=""
                    style="width: 20px; height: 20px; margin-left: 10px"
                  />
                </div>
                <div class="department-title">
                  <span>{{ joinedItems }}</span>
                </div>
              </div>
            </div>
            <div class="btn-layout">
              <button
                class="delete"
                :onclick="'parent.window.sw.open(\'个人中心\',\'/erp/?m=xiaoyou&tabIndex=1#/wiki/myArticle\')'"
              >
                草稿箱
              </button>
              <button
                class="publish"
                :onclick="'parent.window.sw.open(\'文章编辑\',\'/erp/?m=xiaoyou#/wiki/wiki-publish\')'"
              >
                发布
              </button>
            </div>
          </div>
        </div>
        <div class="wiki-header">
          <div class="wiki-search">
            <h1 class="project-name"></h1>
            <el-autocomplete
              ref="input1"
              hide-loading
              class="inline-input"
              v-model="search_word"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              :trigger-on-focus="false"
              @focus="searchFocus"
              @keyup.enter.native="getResult(search_word, selectCato)"
            >
              <el-select
                v-model="selectCato"
                slot="prepend"
                placeholder="全部"
                @change="selectChange"
                style="width: 100px"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="(item, index) in selectOptions"
                  :key="index"
                ></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getResult(search_word, selectCato)"
              >
              </el-button>
            </el-autocomplete>
          </div>
        </div>
        <div
          class="main"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="types">
            <div
              :class="type.id == params.typeId ? 'type-active' : 'type'"
              v-for="type in visibleTypes"
              :key="type.id"
              @click="handleType(type.id)"
            >
              {{ type.name }}
            </div>
            <div
              class="iconfont"
              @click="showAllTyps"
              v-if="typesList && typesList.length > 2"
            >
              {{ showAll ? "&#xe63a;" : "&#xe60c;" }}
            </div>
          </div>
          <div class="tags">
            <div
              :class="tag.name == params.tag ? 'tag-active' : 'tag'"
              v-for="(tag, index) in visibleTags"
              :key="tag.id"
              @click="handleTag(tag, index)"
            >
              #{{ tag.name }}#
            </div>
            <div
              class="iconfont"
              v-if="tagsList && tagsList.length > 2"
              @click="isShowAllTags = !isShowAllTags"
            >
              {{ isShowAllTags ? "&#xe63a;" : "&#xe60c;" }}
            </div>
          </div>
          <div class="flex-layout">
            <div style="width: 100%">
              <div
                class="knowledge"
                v-for="item in pageInfo.content"
                :key="item.id"
                @click="
                  $router.push({
                    name: 'wiki-article-h5',
                    query: { id: item.id },
                  })
                "
              >
                <h2>
                  <span class="pinned" v-if="item.top">置顶</span>
                  <!-- <span
                    class="department"
                    v-if="item.level.toUpperCase() == 'DEPT'"
                    >{{ item.deptName }}</span
                  > -->
                  <span v-html="item.subject"></span>
                </h2>
                <p v-if="item.digest" v-html="item.digest"></p>
                <!-- 附件展示 -->
                <Attachments
                  @imgPreview="imgPreview"
                  :fileList="item.files"
                  v-if="item.files && item.files.length != 0"
                  :searchQuery="params.query"
                />
                <div class="stats">
                  <p>发布时间：{{ item.updateTime | date }}</p>
                  <p>发布者：{{ item.author }}</p>
                  <p>
                    浏览权限：{{
                      item.level == "PUB" ? "所有人" : "指定部门查看"
                    }}
                  </p>
                </div>
              </div>
              <div
                class="no-data"
                v-if="pageInfo.content && pageInfo.content.length == 0"
              >
                暂无内容
              </div>
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pageInfo.totalElements"
                :current-page="params.page + 1"
                :page-size="params.size"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
            <div class="right-box">
              <div class="rate" v-if="commentList && commentList.length > 0">
                <div class="title">最近点评</div>
                <div
                  class="each"
                  v-for="(rate, num) in commentList"
                  :key="num"
                  :style="{
                    border: num == commentList.length - 1 ? 'none' : '',
                  }"
                  @click="
                    $router.push({
                      name: 'wiki-article-h5',
                      query: { id: rate.wikiId },
                    })
                  "
                >
                  <div class="user-info">
                    <img :src="rate.avatar" alt="" class="img" />
                    <div class="name">{{ rate.name }}</div>
                    <div class="text">点评了</div>
                  </div>
                  <div class="sub-title">
                    {{ rate.subject }}
                  </div>
                  <div class="detail">"{{ rate.content }}"</div>
                  <div class="date">
                    {{ rate.createTime | timeBetweenString }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <ul class="list">
              <li>发布数量：</li>
              <li v-for="(p, c) in deptPublishCount" :key="c">
                {{ p.deptName + "(" + p.count + ")" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-card>
    <el-dialog
      title="图片预览"
      :visible.sync="dialogImg"
      width="90%"
      :center="true"
      @close="closeImgShow"
    >
      <div class="dialog-laout">
        <img
          :src="dialogImgUrl"
          alt=""
          style="max-with: 100%; max-height: 100%"
        />
        <!--<el-button type="primary" @click="downImg">下载图片</el-button>-->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios_2 from "axios";
import Attachments from "./components/AttachmentsH5";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // 导入相对时间插件
import "dayjs/locale/zh-cn"; // 导入本地化语言
import local from "@/utils/local";
dayjs.locale("zh-cn"); // 使用本地化语言
// 注册插件
dayjs.extend(relativeTime);
export default {
  components: {
    Attachments,
  },
  data() {
    return {
      // 是否展示全部标签
      isShowAllTags: false,
      // 是否展示全部类型
      showAll: false,
      // 标签列表
      tagsList: [],
      // 类型列表
      typesList: [],
      search_word: "", //搜索内容
      params: {
        page: 0,
        size: 10,
        type: 0,
        query: "",
        enableWikiAdmin: false,
        tag: "",
        typeId: "",
      },
      // 搜索类型
      selectOptions: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "内容",
        },
        {
          value: 2,
          label: "标题",
        },
        {
          value: 3,
          label: "作者",
        },
      ],
      selectCato: "",
      pageInfo: {},
      dialogImg: false,
      dialogImgUrl: "", //预览图url
      loading: true,
      imgName: "", //预览图片名称
      //是否超管
      isWikiAdmin: false,
      userInfo: {}, //用户信息
      recentList: [], //文章最近更新列表
      commentList: [], //最近评论列表
      deptPublishCount: [], //部门发布数量
      log: null,
    };
  },
  mounted() {
    const url = window.location.href;
    const params = this.getUrlParams(url);
    this.log = params.token;
    console.log(this.log,'打印token')
    // let timestamp = 1000*10000 +new Date().getTime();
    // let obj = {
    //   ticket: this.log,
    //   expireTimestamp:timestamp ,
    //   expire:10000
    // };
    // local.set("xy_token",obj);
    // console.log(obj,'打印ticket');
    this.getWikiUserInfo();

  },
  filters: {
    timeBetweenString(timestamp) {
      return dayjs(timestamp).fromNow();
    },
  },
  computed: {
    filteredItems() {
      if (!this.params.query) {
        console.log("无搜索内容", this.pageInfo);
        return this.pageInfo.content;
      }
      const regex = new RegExp(this.params.query, "gi");
      return this.pageInfo.content.filter((item) => {
        return (
          item.subject.match(regex) ||
          item.digest.match(regex) ||
          (item.files && item.files.some((file) => file.name.match(regex)))
        );
      });
    },
    joinedItems() {
      if (this.userInfo.depts) {
        return this.userInfo.depts.join(" , ");
      } else {
        return "";
      }
    },
    //
    visibleTypes() {
      if (this.showAll) {
        return this.typesList;
      } else {
        return this.typesList.slice(0, 2); // 设置显示的标签数量
      }
    },
    visibleTags() {
      if (this.isShowAllTags) {
        return this.tagsList;
      } else {
        return this.tagsList.slice(0, 2);
      }
    },
  },
  methods: {
    sendRequest(_url, _config) {
      axios_2.interceptors.request.use((config) => {
        let token = this.log;
        // console.log(token, "sssssss5ssss");
        if (token) {
          config.headers["xiaoyou-token"] = token;
        }
        return config;
      });
      let conf = _config || {};
      conf.async = conf.async || false;
      conf.method = conf.method || "get";
      conf.data = conf.data || {};
      conf.headers = conf.headers || {};
      conf.success = _config.success || function () {};
      conf.error =
        _config.error ||
        function (data) {
          this.$message({
            message: data.message,
            type: "error",
          });
        };
      conf.complete = _config.complete || function () {};
      if (conf.async === true) {
        //同步处理
        console.log("sd");
      } else {
        //异步处理
        if ("get" === conf.method.toLowerCase()) {
          axios_2
            .get(_url, {
              params: conf.data,
              headers: conf.headers,
            })
            .then((res) => {
              if (res.data.result != null) {
                if (res.data.result) {
                  conf.success(res.data.data, res.data);
                } else {
                  conf.error(res.data, res);
                }
              } else {
                if (res.data.error == 0) {
                  conf.success(res.data.data, res.data);
                } else {
                  conf.error(res.data, res);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              conf.error(err);
            });
        } else {
          axios_2
            .post(_url, conf.data, { headers: conf.headers })
            .then((res) => {
              if (res.data.result != null) {
                if (res.data.result) {
                  conf.success(res.data.data, res.data);
                } else {
                  conf.error(res.data, res);
                }
              } else {
                if (res.data.error == 0) {
                  conf.success(res.data.data, res.data);
                } else {
                  conf.error(res.data, res);
                }
              }
            })
            .catch((err) => {
              console.log(err);
              conf.error(err);
            });
        }
      }
    },
    getUrlParams(url) {
      let params = {};
      const paramStr = url.split("?")[1];
      if (paramStr) {
        const paramArr = paramStr.split("&");
        for (const param of paramArr) {
          const [key, value] = param.split("=");
          params[key] = decodeURIComponent(value);
        }
      }
      return params;
    },

    // 预览图关闭
    closeImgShow() {
      this.imgName = "";
      this.dialogImgUrl = "";
    },
    //获取部门发布数量
    getDeptPublishCount() {
      this.sendRequest("/api/v1/usr/basic/wiki/deptPublishCount", {
        method: "GET",
        success: (data) => {
          this.deptPublishCount = data;
        },
        error: (err) => {},
      });
    },

    // 选中标签
    handleTag(e, i) {
      if (e.name == this.params.tag) {
        this.params.tag = "";
      } else {
        this.visibleTags.splice(i, 1);
        this.visibleTags.unshift(e);
        this.params.tag = e.name;
      }
      this.params.page = 0;
      this.getPage();
    },
    // 选中类型
    handleType(e) {
      if (e == this.params.typeId) {
        this.params.typeId = "";
      } else {
        this.params.typeId = e;
      }
      this.params.page = 0;
      this.getPage();
    },
    // 是否展示全部标签
    showAllTyps() {
      this.showAll = !this.showAll;
    },
    // 获取最近评论
    getLatestCommentList() {
      this.sendRequest("/api/v1/usr/basic/wiki/latestCommentList", {
        data: {
          size: 6,
          enableWikiAdmin: this.params.enableWikiAdmin,
        },
        method: "GET",
        success: (data) => {
          this.commentList = data;
        },
        error: (err) => {},
      });
    },
    // 获取全部标签
    getAllTags() {
      this.sendRequest("/api/v1/usr/basic/wiki/getAllTagAndType ", {
        method: "GET",
        success: (data) => {
          this.tagsList = data.tags;
          this.typesList = data.types;
        },
        error: (err) => {},
      });
    },
    // 获取最近修改
    recentUpdate() {
      this.sendRequest("/api/v1/usr/basic/wiki/recentUpdate", {
        data: { size: 6 },
        method: "GET",
        success: (data) => {
          this.recentList = data;
        },
        error: (err) => {},
      });
    },
    // 切换管理员身份
    adminSwitch() {
      this.$message.success("权限切换成功");
      this.params.enableWikiAdmin = !this.params.enableWikiAdmin;
      this.params.page = 0;
      this.params.tag = "";
      this.params.typeId = "";
      this.params.query = "";
      this.getPage(); //文章列表
      this.getLatestCommentList(); //最新评论
    },
    //获取用户信息
    getWikiUserInfo() {
      this.sendRequest("/api/v1/usr/basic/wiki/wikiUserInfo", {
        method: "GET",
        success: (data) => {
          this.isWikiAdmin = data.wikiAdmin;
          this.userInfo = data;
          this.getPage();
          this.recentUpdate();
          this.getAllTags();
          this.getLatestCommentList();
          this.getDeptPublishCount();
        },
        error: (err) => {
          console.log(err);
        },
      });
    },
    // 图片下载
    downImg() {
      swei.downFile(this.dialogImgUrl, this.imgName);
    },
    // 图片预览
    imgPreview(e) {
      this.imgName = e.name;
      this.dialogImgUrl = e.url;
      this.dialogImg = true;
    },
    // 取消建议框
    searchFocus() {
      this.$refs.input1.activated = false;
    },
    // 获取页面
    getPage() {
      console.log(this.params, "入参");
      // 开启加载
      this.loading = true;
      this.sendRequest("/api/v1/usr/basic/wiki/queryPage", {
        data: this.params,
        method: "get",
        success: (data) => {
          this.loading = false;
          this.pageInfo = data;
          this.$message.success("查询成功");
        },
        error: (err) => {
          this.$message.error(err.message || "加载失败");
        },
      });
    },
    textHighlight(text) {
      if (!this.params.query) {
        return text;
      }
      const regex = new RegExp(`(${this.params.query})`, "gi");
      return text.replace(regex, '<span style="color:red">$1</span>');
    },
    // 搜索
    querySearch() {},
    //当前页变化
    currentChange(e) {
      this.params.page = e - 1;
      this.getPage();
    },
    // 搜索类型改变
    selectChange(e) {
      console.log(e);
    },
    getResult(keyword, type) {
      console.log(keyword, type);

      this.params.page = 0;
      this.params.type = this.params.type == "" ? 0 : this.params.typ;
      this.params.query = keyword;
      this.getPage();
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-laout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 20px;
    // width:;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
a {
  color: inherit;
  text-decoration: none;
}
.body-z {
  width: 100%;
  background-color: #f5f5f5;
}
.wiki-header {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(./img/back.bb0f134c.png) no-repeat;
  background-size: 100%;
}
.footer {
  margin: 120px 0 43px 0;
  .list {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #7b88a0;
    li {
      margin-right: 30px;
    }
  }
}
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 1460px;
  margin: 10px 0 20px 0;
  .tag {
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 400;
    color: #1b60ec;
    margin-bottom: 10px;
    margin-right: 40px;
    cursor: pointer;
    transition: transform 0.3s ease; /* 添加一个过渡效果，使动画更平滑 */
  }
  .tag:hover {
    color: #3d3d3d;
    font-weight: 700;
    transform: scale(1.2);
  }
  .tag-active {
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: bolder;
    color: #3d3d3d;
    margin-bottom: 10px;
    margin-right: 40px;
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.3s ease; /* 添加一个过渡效果，使动画更平滑 */
  }
  .iconfont {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0px 10px 0;
  }
}
.types {
  width: 1460px;
  // padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e7ec;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  .iconfont {
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0px 10px 0;
  }
}

.type {
  min-width: 80px;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 15px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  color: #5d5d5d;
  line-height: 40px;
  margin: 0 20px 10px 0;
  cursor: pointer;
}
.type:hover {
  background: #fff;
  transition: all 0.4s;
}
.type-active {
  min-width: 80px;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
  font-size: 15px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: center;
  color: #3d3d3d;
  line-height: 40px;
  margin: 0 20px 10px 0;
  cursor: pointer;
}
.flex-layout {
  display: flex;

  .right-box {
    margin-left: 20px;
    .title {
      font-size: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold;
      font-weight: 700;
      color: #3d3d3d;
      margin-bottom: 30px;
    }
    .revision {
      width: 340px;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 30px;
      box-sizing: border-box;
      .each {
        display: flex;
        margin-bottom: 24px;
        align-items: flex-start;
        .date {
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          color: #bdbdbd;
          margin-right: 8px;
        }
        .sub-title {
          font-size: 18px;
          font-family: Source Han Sans CN, Source Han Sans CN-Medium;
          font-weight: 500;
          color: #3d3d3d;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
          cursor: pointer;
        }
        .sub-title:hover {
          color: #1b60ec;
          font-weight: bold;
        }
      }
    }
    .rate {
      width: 340px;
      background: #ffffff;
      border-radius: 10px;
      padding: 30px;
      box-sizing: border-box;
      .date {
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #7b88a0;
        margin-top: 15px;
        margin-left: 10px;
      }
      .each {
        cursor: pointer;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 30px;
        .user-info {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background: lightblue;
          }
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            color: #3d3d3d;
            margin: 0 10px;
          }
          .text {
            font-size: 18px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            color: #6d6d6d;
          }
        }
        .sub-title {
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #1b60ec;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
          margin-bottom: 10px;
        }
        .detail {
          width: 280px;
          background: #f9faff;
          border-radius: 8px;
          font-size: 18px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #3d3d3d;
          padding: 15px;
          box-sizing: border-box;
        }
      }
    }
  }
}
.main {
  width: 100%;
  height: auto;
  background-color: #f0f3f8;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
}
.knowledge {
  width: 1100px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
}
.no-data {
  width: 100%;
  height: 200px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 24px;
}
.knowledge h2 {
  font-size: 20px;
  font-weight: bold;
  color: #1b60ec;
  margin: 0 0 10px;
  overflow: hidden; // 表示当内容溢出元素框时内容会被修剪，并且其余内容是不可见的。
  text-overflow: ellipsis; // 表示当文本溢出包含元素时发生的事情显示省略符号来代表被修剪的文本。
  white-space: nowrap; // 表示文本不进行换行：
}
.knowledge p {
  font-size: 16px;
  color: #545d6f;
  text-indent: 32px;
  line-height: 28px;
  display: -webkit-box; /*弹性伸缩盒子*/
  -webkit-box-orient: vertical; /*子元素垂直排列*/
  line-clamp: 3;
  -webkit-line-clamp: 3; /*显示的行数*/
  overflow: hidden; /*溢出部分隐藏*/
  text-overflow: ellipsis; /*溢出部分用省略号显示*/
}
.attachments {
  width: 100%;
  background-color: #f9faff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.stats {
  display: flex;
  justify-content: flex-start;
}
.stats p {
  text-indent: 0;
  margin-right: 30px;
  color: #7b88a0;
}
.pinned {
  color: #ffffff !important;
  background-color: #f66c15;
  font-weight: bolder;
  margin-right: 10px;
  border-radius: 6px;
  padding: 5px;
  line-height: 30px;
  font-size: 17px;
}
.department {
  color: #ffffff !important;
  background-color: #4dd0e1;
  font-weight: bolder;
  margin-right: 10px;
  border-radius: 6px;
  padding: 5px;
  line-height: 30px;
  font-size: 17px;
}
.manage {
  color: #ffffff !important;
  background-color: #fb464e;
  font-weight: bolder;
  margin-right: 10px;
  border-radius: 6px;
  padding: 5px;
  line-height: 30px;
  font-size: 17px;
}
.file-docx {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
}
.file-docx h3 {
  font-size: 16px;
  margin: 6px 0 0;
  display: -webkit-box; /*弹性伸缩盒子*/
  -webkit-box-orient: vertical; /*子元素垂直排列*/
  line-clamp: 1;
  -webkit-line-clamp: 1; /*显示的行数*/
  overflow: hidden; /*溢出部分隐藏*/
  text-overflow: ellipsis; /*溢出部分用省略号显示*/
}
.file-docx p {
  text-indent: 0;
}
.file-docx img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.paging {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
.pagination {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  color: #3d3d3d;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.pagination:hover,
.select {
  color: #ffffff;
  background-color: #1e80ff;
}
.next-page {
  width: 80px;
}
.project-name {
  display: block;
  width: 300px;
  height: 90px;
  background: url(./img/logo.png);
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.wiki-search {
  text-align: center;
}
.inline-input {
  margin: 30px;
}
.inline-input {
  width: 700px;
}
.publish {
  width: 100px;
  height: 40px;
  background-color: #1b60ec;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.delete {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  color: #5b6170;
  border: #e9eaee 1px solid;
  background-color: #ffffff;
  margin: 0 10px;
  cursor: pointer;
}
.wiki-publish {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.user-publist {
  width: 100%;
  height: 60px;
  background: #ffffff;
}
.wiki-user {
  display: flex;
  align-items: center;
  cursor: pointer;

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .right {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    // padding: 4px 0;
    box-sizing: border-box;
    .name-admin {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .name {
        font-size: 18px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #3d3d3d;
      }
      .admin {
        display: flex;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
        // width: 44px;
        height: 18px;
        background: linear-gradient(90deg, #407fff, #1b60ec);
        border-radius: 9px;
        font-size: 12px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 18px;
        margin-left: 10px;
      }
    }
    .department-title {
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      color: #9d9d9d;
    }
  }
}
.user img {
  width: 60px;
  height: 60px;
  border-radius: 40px;
}
.user h3 {
  margin-left: 8px;
  font-size: 16px;
  color: #3d3d3d;
}
.toggle p {
  margin-left: 8px;
  font-size: 16px;
  color: #6d6d6d;
}
.toggle,
.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.toggle img {
  width: 24px;
  height: 24px;
}

:deep(.el-pagination) {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .wiki-header {
    height: 240px;
  }
  .user-publist {
    display: none;
  }
  .project-name {
    width: 60%;
  }
  .inline-input {
    width: 90%;
    margin: 20px 10px;
  }
  .main {
    padding: 10px;
  }
  .types {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 0px solid #e4e7ec;
  }
  .type,
  .type-active {
    font-size: 1rem;
    margin: 0px 10px 10px 0;
  }
  .type:hover {
    background: none;
  }
  .tag:hover {
    color: #1b60ec !important;
    font-size: 0.9 !important;
    transform: scale(1) !important;
  }
  .tags {
    width: 100%;
    margin: 10px 0px 20px;
    // display: none;
  }
  .tag-active {
    margin: 10px;
    font-size: 0.9rem !important;
  }
  .tag {
    margin: 10px;
    font-size: 1rem !important;
  }
  .iconfont {
    line-height: 18px;
    height: 18px;
    margin: 0 !important;
  }
  .knowledge {
    width: 100%;
    margin: 0 0 15px;
    padding: 15px;
  }
  .knowledge h2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
    line-height: 30px;
    font-size: 18px;
  }
  .flex-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .stats {
    flex-wrap: wrap;
    margin: 10px 0 0;
  }
  .stats p {
    font-size: 15px;
  }
  .pinned {
    padding: 3px;
    font-size: 0.8rem;
  }
  .el-pagination {
    margin: 20px 0 50px;
  }
  .right-box {
    margin-left: 0 !important;
  }
  .rate {
    width: 100% !important;
    padding: 15px !important;
  }
  .title {
    font-size: 18px !important;
  }
  .name {
    font-size: 16px !important;
  }
  .text {
    font-size: 14px !important;
  }
  .list {
    flex-wrap: wrap;
  }
  .list li {
    margin: 10px 0;
    margin-right: 20px !important;
    font-size: 15px;
  }
  .detail {
    width: 100% !important;
    font-size: 16px !important;
  }
}
</style>