<template>
  <div>
    <h3 class="file" id="file">已上传的附件</h3>
    <div
      class="attachments"
      v-loading="loading"
      element-loading-text="获取文件中"
      element-loading-spinner="el-icon-loading"
    >
      <div
        class="file-docx"
        v-for="(file, i) in fileList"
        :key="i"
        @click.stop="dialogShow(file)"
      >
        <img
          v-if="file.type.toUpperCase() == '.DOCX'"
          src="../img/DOCX图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.DOC'"
          src="../img/DOC图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.JPEG'"
          src="../img/JPEG图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.PDF'"
          src="../img/PDF图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.PPT'"
          src="../img/PPT图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.XLSX'"
          src="../img/XLSX图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.PNG'"
          src="../img/PNG图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.JPG'"
          src="../img/JPG图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.7Z'"
          src="../img/7Z图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.XLS'"
          src="../img/XLS图标.png"
        />
        <img
          v-else-if="file.type.toUpperCase() == '.ZIP'"
          src="../img/ZIP图标.png"
        />
        <img v-else src="../img/wenjian.png" />
        <div class="file-attribute">
          <h3 v-html="file.name"></h3>
          <p>{{ file.size | sizeTostr }}</p>
        </div>
        <el-popover placement="top" width="160" trigger="hover">
          <p>确定删除此文件吗？</p>
          <div style="text-align: center; margin: 0">
            <el-button type="danger" size="mini" @click="fileDelete(i)"
              >确定</el-button
            >
          </div>
          <img
            slot="reference"
            v-if="edit"
            class="del-img"
            src="../img/delete.png"
            style="width: 20px; height: 20px"
            alt=""
            @click.stop="fileDelete(i)"
          />
        </el-popover>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["fileList", "imgPreview", "edit", "fileDel", "searchQuery"],
  data() {
    return {
      loading: false,
    };
  },
  filters: {
    //文件大小
    sizeTostr(size) {
      var data = "";
      if (size < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        data = size.toFixed(2) + "B";
      } else if (size < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        data = (size / 1024).toFixed(2) + "KB";
      } else if (size < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizestr = data + "";
      var len = sizestr.indexOf(".");
      var dec = sizestr.substr(len + 1, 2);
      if (dec == "00") {
        //当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
      }
      return sizestr;
    },
  },
  computed: {
    filteredItems() {
      if (!this.searchQuery) {
        return this.fileList;
      }
      const regex = new RegExp(this.searchQuery, "gi");
      return this.fileList.filter((item) => {
        return item.name.match(regex);
      });
    },
  },
  methods: {
    // 文件删除
    fileDelete(e) {
      this.$emit("fileDel", e);
    },
    highlightText(text) {
      if (!this.searchQuery) {
        return text;
      }

      const regex = new RegExp(`(${this.searchQuery})`, "gi");
      return text.replace(regex, '<span style="color:red;">$1</span>');
    },
    //图片预览 文件处理
    dialogShow(e) {
      if (
        e.type.toUpperCase() == ".PNG" ||
        e.type.toUpperCase() == ".JPEG" ||
        e.type.toUpperCase() == ".JPG"
      ) {
        this.$emit("imgPreview", e);
      } else {
        this.$alert(`文件下载地址：${e.url}`, "提示", {
          confirmButtonText: "复制链接",
          callback: (action) => {
            console.log(action);
            if (action == "confirm") {
              this.copyContact(e.url);
            }
          },
        });
      }
    },
    copyContact(copyContent) {
      const textField = document.createElement("textarea");
      textField.innerText = copyContent;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      this.$message.success("下载链接已复制！");
    },
    downFile(url, fileName) {
      let _this = this;
      this.loading = true;
      const x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        _this.loading = false;
      };
      x.send();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.file {
  margin-top: 20px;
}
.attachments {
  width: 100%;
  background-color: #f9faff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .file-docx {
    width: 270px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    position: relative;
    .del-img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .file-docx h3 {
    max-width: 170px;
    font-size: 16px;
    margin: 6px 0 0;
    display: -webkit-box; /*弹性伸缩盒子*/
    -webkit-box-orient: vertical; /*子元素垂直排列*/
    line-clamp: 1;
    -webkit-line-clamp: 1; /*显示的行数*/
    overflow: hidden; /*溢出部分隐藏*/
    text-overflow: ellipsis; /*溢出部分用省略号显示*/
  }
  .file-docx p {
    text-indent: 0;
  }
  .file-docx img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
@media (max-width: 768px) {
  .el-message-box {
    width: 320px !important;
  }
  .file {
    font-size: 16px;
  }
}
</style>
<style>
.el-message-box {
  width: 80% !important;
  overflow: hidden;
}
.el-message-box__message p {
  word-wrap: break-word;
}
</style>