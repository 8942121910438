<template>
  <div id="login">
    <img src="@/assets/img/login_logo-2.png" class="logo-1" alt="" />
    <div class="welcome-layout">
      <p>欢迎使用</p>
      <p>小游智行SAAS管理后台</p>
    </div>
    <div class="login-layout">
      <p>微信扫码登录</p>
      <p>请使用微信扫描二维码授权登录</p>
      <div v-if="cc == 1">
        <div ref="qrcode" class="code"></div>
      </div>
      <div v-if="moni.dev == 1" style="margin-top: 20px">
        <input type="text" v-model="moni.uid" placeholder="请输入用户id" />
        <button type="button" @click="doMoni">模拟登录</button>
      </div>
    </div>
    <!-- 账号密码登录部分 -->
    <!-- <div class="login">
			<div class="login-left">
				<img src="@/assets/img/rid.png" class="img" alt="">
			</div>
			<div class="login-right">
				<div class="login-logo">
					<img class="img" src="@/assets/img/login_logo.png" alt=""/>
					<div class="text">欢迎使用</div>
					<h2 class="title">小游科技管理后台</h2>
				</div>
				<div v-if="loginSwitch">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="rule-form">
						<el-form-item prop="name">
							<el-input class="ipt" v-model="ruleForm.name" placeholder="用户名">
								<img class="font" slot="prefix" src="@/assets/img/user.png" alt=""></el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input @keyup.enter.native="submitForm('ruleForm')" class="ipt" v-model="ruleForm.password" placeholder="密码" type="password">
								<img class="font" slot="prefix" src="@/assets/img/psd.png" alt="">
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-button class="btn" type="primary" w @click="submitForm('ruleForm')">登录
							</el-button>
						</el-form-item>
					</el-form>
					<div class="forget">忘记密码？</div>
				</div>
				
				<div v-if="cc==1">
					<div ref="qrcode" class="code" :style="'width:200px; height:200px; margin:0px auto;' + (loginSwitch ? 'display:none;' : '')"></div>
				</div>
				<div class="quanxian" v-if="cc==2">
					<img class="dengdai" src="../../assets/img/shiming@2x.png">
					<p class="tishi">实名认证</p>
					<p class="miaoshu">{{ccMsg}}</p>
				</div>
				<div class="quanxian" v-if="cc==3">
					<img class="dengdai" src="../../assets/img/dengdai@2x.png">
					<p class="tishi">等待授权</p>
					<p class="miaoshu">{{ccMsg}}</p>
					<button class="tuichu">退出</button>
				</div>
			</div>
			<div class="switch">
				<img v-if="loginSwitch" src="../../assets/img/switch_2.png" alt="">
				<img v-else src="../../assets/img/switch_1.png" alt="">
			</div>
			<div v-if="moni.dev==1">
				<input type="text" v-model="moni.uid" placeholder="请输入用户id"/>
				<button type="button" @click="doMoni">模拟登录</button>
			</div>
		</div> -->
  </div>
</template>
<script>
import axios from "axios";
let thsQrCode;
let isMounted = false;
let sessionCode = null;
let currentCode = null;
let thsdev = 0;
let wxid = 1;
import swei from "@/utils/common-utils";
export default {
  data() {
    return {
      moni: {
        uid: 1,
        dev: thsdev,
      },
      cc: 1, //1默认 2绑定 3授权
      ccMsg: "",
      //登录方式切换
      loginSwitch: false,
      ruleForm: {
        name: "",
        password: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入正确的用户名", trigger: "blur" },
          {
            min: 6,
            max: 11,
            message: "长度在 6 到 11 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入正确的密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    console.log("开始请求");

    console.log("载入初始数据");
    isMounted = true;
    console.log("进入mounted");
    let size = window.innerWidth >= 2560 ? 240 : 180;
    thsQrCode = new QRCode(this.$refs.qrcode, {
      width: size,
      height: size,
    });
    this.freshCode();
    this.moniLogin();
    // thsQrCode.makeCode("http://doc.appshops.cn/thrd/wx/1/rd/token_" + '222');
  },
  methods: {
    moniLogin() {
      swei.common.ajax("/api/profile", {
        async: false,
        success: (data) => {
          console.log("profile=");
          console.log(data);
          // alert(data);
          if ("dev" === data.profile) {
            console.log("开启模拟登录");
            this.moni.dev = 1;
          } else {
            this.moni.dev = 0;
          }
          wxid = data.wxid;
        },
      });
    },
    doMoni: function () {
      console.log("点击成功");
      let ths = this;
      swei.common.ajax(
        "/api/user/login/dev?session=" + sessionCode + "&id=" + this.moni.uid,
        {
          method: "post",
          success: (data) => {
            // console.log("12312321");
            //
            // this.$router.push("/menu");
            // console.log("跳轉稱呼");
          },
          error: (err) => {},
        }
      );
    },
    checkSession: function () {
      if (sessionCode != null) {
        let sessionCode1 = sessionCode + "";
        let ths = this;
        axios
          .post(
            "/api/user/login-check?session=" +
              sessionCode +
              "&_t=" +
              new Date().getTime(),
            {}
          )
          .then((data1) => {
            if (sessionCode1 != currentCode) {
              return;
            }
            let data = data1.data;
            if (data.result) {
              let rs = data.data;
              if (rs == null) {
                setTimeout(function () {
                  // console.log(sessionCode1,currentCode)
                  if (sessionCode1 == currentCode) ths.checkSession();
                }, 1000);
              } else {
                console.log(rs, "水水水水");
                swei.requestToken(10);
                // location.href = "/main.html";
                this.$router.push("/menu");
              }
            } else {
              if (-100 == data.errorCode) {
                setTimeout(function () {
                  ths.freshCode();
                }, 100);
                return;
              }
              if (-200 == data.errorCode) {
                ths.cc = 2;
                ths.ccMsg = data.message;
                return;
              }
              if (-201 == data.errorCode) {
                ths.cc = 3;
                ths.ccMsg = data.message;
                return;
              }
              if (200 == data.errorCode) {
                // location.href = "/main.html";
                // this.$router.push("/menu");
              }
              console.log("result出错了");
            }
          })
          .catch(function (error) {
            console.log("catch出错了");
            console.error(error);
          });
      }
    },
    freshCode: function () {
      if (!isMounted) return;
      let ths = this;
      console.log("freshCode");
      axios
        .post("/api/user/login-token", {})
        .then(function (data1) {
          let data = data1.data;
          if (data.result) {
            let rs = data.data;
            let url1 =
              "http://doc.appshops.cn/thrd/wx/" + wxid + "/rd/" + rs.ticket;
            sessionCode = rs.session;
            // console.log("二维码内容:", url1)
            thsQrCode.makeCode(url1);
            currentCode = sessionCode + "";
            setTimeout(function () {
              ths.checkSession();
            }, 1000);
          } else {
            if (200 == data.errorCode) {
              console.log("跳转了~！");
              ths.$router.push("/menu");
            }
            if (-100 == data.errorCode) {
              setTimeout(function () {
                ths.freshCode();
              }, 10);
            }

            console.log("result出错了");
            // console.error(data);
          }
        })
        .catch(function (error) {
          console.log("catch出错了");
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>

.font {
  margin-top: 18px;
}
@media (max-width: 2560px) {
  #login {
    width: 100%; /* 或者设置固定宽度 */
    height: 1288px; /* 或者设置固定高度 */
    background: url("~@/assets/img/banner.png") no-repeat center;
    background-size: 100% 1288px !important;
    .login-layout {
      position: absolute;
      top: 400px;
      right: 600px;
      width: 480px;
      height: 610px;
      p {
        font-size: 26px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #3d3d3d;
      }
      p:nth-of-type(2) {
        font-size: 19px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #9d9d9d;
        margin: 20px 0 80px 0;
      }
    }
    .code {
      width: 240px;
      height: 240px;
    }
  }
}
@media (max-width: 1920px) {
  #login {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: url("~@/assets/img/banner.png") no-repeat center;
    background-size: 100% 100%;
    .login-layout {
      position: absolute;
      top: 150px;
      right: 2%;
      width: 360px;
      height: 459px;
      p {
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #3d3d3d;
      }
      p:nth-of-type(2) {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #9d9d9d;
        margin: 15px 0 60px 0;
      }
    }
    .code {
      width: 180px;
      height: 180px;
    }
  }
}
@media (max-width: 1200px){
  #login {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: url("~@/assets/img/banner.png") no-repeat center;
    background-size: 1200px 1080px;
    .login-layout {
      position: absolute;
      top: 150px;
      right: 20px;
      width: 360px;
      height: 459px;
      p {
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #3d3d3d;
      }
      p:nth-of-type(2) {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        color: #9d9d9d;
        margin: 15px 0 60px 0;
      }
    }
    .code {
      width: 180px;
      height: 180px;
    }
    .welcome-layout {
      top: 150px!important;
    }
  }
}
#login {
  position: relative;

  .logo-1 {
    width: 212px;
    height: 59px;
    position: absolute;
    left: 100px;
    top: 60px;
  }
  .welcome-layout {
    position: absolute;
    top: 150px;
    left: 10%;
    p {
      font-size: 30px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold;
      font-weight: 700;
      color: #3d3d3d;
    }
    p:nth-of-type(2) {
      font-size: 40px;
      margin-top: 20px;
    }
  }
  .login-layout {
    background: #f7faff;
    border: 1px solid #ffffff;
    border-radius: 10px;
    box-shadow: 0px 5px 15px 0px rgba(34, 140, 244, 0.1);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 80px 0 90px 0;
    box-sizing: border-box;
  }
  //切换登录方式登录
  .switch {
    position: absolute;
    top: 0;
    right: 0;
    width: 64px;
    height: 64px;
    background-color: #5a8dee;
    cursor: pointer;
  }

  // 账号密码登录部分
  .login {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    height: 600px;
    overflow: hidden;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    display: flex;

    // 左侧图片
    .login-left {
      width: 600px;
      height: 600px;
      background: #08f1e4;

      .img {
        width: 100%;
        height: 100%;
      }
    }

    //右侧用户操作登录部分
    .login-right {
      position: relative;
      width: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .code {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border: 1px solid #ccc;
      }

      .login-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px;

        .img {
          width: 56px;
          height: 56px;
          // background: pink;
        }

        .text {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #475f7b;
          margin: 6px 0 12px 0;
        }

        .title {
          width: 160px;
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          color: #475f7b;
        }
      }

      .forget {
        align-self: start;
        // margin-left: 32px;
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #727e8c;
      }

      .bot-notice {
        position: absolute;
        bottom: 40px;
        width: 120px;
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #c7cfd6;
      }
    }

    .btn {
      width: 296px;
      height: 56px;
      background: #5a8dee;
      border-radius: 8px;
    }
  }
}

// 表单样式
.rule-form {
  margin-top: 48px;
}

// ipt框样式
:deep(.el-input__inner) {
  width: 296px;
  height: 56px;
  background: #f2f4f4;
  border-radius: 8px;
  border: none;
}

.quanxian {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  padding-top: 30px;
}

.dengdai {
  width: 80px;
  height: 80px;
}

.tishi {
  margin-top: 20px;
  color: #3d3d3d;
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
}

.miaoshu {
  margin-top: 10px;
  color: #3d3d3d;
  font-size: 16px;
  line-height: 24px;
}

.tuichu {
  width: 120px;
  height: 40px;
  margin-top: 30px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #eeeeee;
}

.tuichu:hover {
  background-color: #dddddd;
}
</style>