/**
 * @description  存贮封装
 */

export default {
    // 存
    set(key, value) {
        try{
            localStorage.setItem(key, JSON.stringify(value))

        }catch(e){
            console.log('存储错误1111',e);
        }
    },

    // 取
    get(key) {
        if(localStorage.getItem(key)==null)return null;
        return JSON.parse(localStorage.getItem(key))
    },

    // 删除
    remove(key) {
        localStorage.removeItem(key)
    },

    // 全部清空
    clear() {
        localStorage.clear()
    },
}