<template>
  <div class="layout">
    <el-container>
      <el-aside class="el-aside-container" style="width: auto">
        <NavLeft :allMenu="allMenu" />
      </el-aside>
      <el-container>
        <el-header height="" style="padding-bottom: 10px;">
          <RightHeader
            :allMenu="tabsMenu"
            @childEvent="handleChildEvent"
            :parentId="parentId"
          />
        </el-header>
        <el-main class="main">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavLeft from "@/views/Layout/NavLeft.vue";
import RightHeader from "@/views/Layout/RightHeader.vue";
import swei from "@/utils/common-utils";
export default {
  components: {
    NavLeft,
    RightHeader,
  },
  data() {
    return {
      activeWidth: 0,
      allMenu: [],
      tabsMenu: [], //全部菜单
    };
  },
  computed: {
    parentId() {
      return this.$store.state.menuId;
    },
  },
  created() {
    // 使用this.$store.state.XXX可以直接访问到仓库中的状态
    // 宽度适配，就是通过监听页面大小改变，去做动态css
    // window.addEventListener("resize", () => {
    //   const width = document.body.clientWidth;
    //   if (width < 1000) {
    //     this.activeWidth = width - 140 + "px";
    //   } else {
    //     this.activeWidth = width - 240 + "px";
    //   }
    // });
    // let parentId = this.$route.query.parentId || "";
    console.log(this.parentId, "接收");
    // this.$store.commit("updataMenuId", parentId);

    console.log(this.$store.state.menuId, "vuex菜单id");

    this.getRoute(this.parentId);
    this.getTabRoute();
    this.getAllByRolePermission();
    //判断刷新过没有  刷新
    if (!localStorage.getItem("refresh")) {
      localStorage.setItem("refresh", 1);
      history.go(0);
    }
  },
  mounted() {},
  beforeDestroy() {
    this.$local.remove("refresh");
  },
  methods: {
    handleChildEvent(id) {
      // this.parentId = id;
      this.$store.commit("updataMenuId", id);
      this.getRoute(id);
    },
    // 获取权限字符
    getAllByRolePermission() {
      swei.common.ajax("/api/v1/user/getAllByRolePermission", {
        method: "GET",
        success: (data) => {
          this.$local.set("permissionList", data);
        },
        error: (err) => {
          this.$message.error(err.message);
        },
      });
    },
    getTabRoute() {
      swei.common.ajax("/api/erp/user/menu", {
        method: "GET",
        success: (data) => {
          this.tabsMenu = data;
        },
        error: (err) => {
          this.$message.error(err.message);
        },
      });
    },
    //获取路由信息
    getRoute(parentId) {
      console.log("获取路由信息", parentId);
      swei.common.ajax("/api/erp/user/menu", {
        method: "GET",
        data: {
          parentId: parentId,
        },
        success: (data) => {
          data.forEach((obj) => {
            obj.rs["afterUrl"] = this.handleUrl(obj.rs.url, obj.rs.id);

            if (obj.lst.length > 0) {
              obj.lst.forEach((subObj) => {
                subObj.rs["afterUrl"] = this.handleUrl(
                  subObj.rs.url,
                  subObj.rs.id
                );
              });
            }
          });
          this.allMenu = data;
        },
        error: (err) => {
          this.$message.error(err.message);
        },
      });
    },
    handleUrl(url, id) {
      var indexOfHash = url.indexOf("#");
      if (url.includes("erp")) {
        var afterHash = url.slice(indexOfHash + 1);
        if (indexOfHash !== -1) {
          return afterHash;
        }
      } else {
        return `/iframe/${id}?src=` + swei.string.encode(url);
      }
    
    },
  },
};
</script>

<style lang="scss" scoped>
/* 滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
}
.el-aside-container {
  height: 100%;
}
.layout {
  background: url("~@/assets/img/index_banner.png");
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}
.el-header,
.el-footer {
  // background-color: #b3c0d1;
  color: #333;
  text-align: center;
  padding: 0;
  z-index: 99;
}

.el-aside {
  // background-color: #d3dce6;
  // color: #333;
  text-align: center;
  line-height: 200px;
  height: 100%;
}

.el-main {
  width: 100%;
  height: 100%;
  padding: 0;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}
.el-main::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
}
.el-main::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 滚动条轨道颜色 */
}
.el-main::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
}
.el-main::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 滚动条滑块hover颜色 */
}

/* 兼容不是所有浏览器都支持的属性 */
.custom-scrollbar {
  overflow-y: scroll;
}

.el-container {
  height: 100%;
}
body > .el-container {
  margin-bottom: 40px;
  height: 100%;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>