import axios1 from "axios";
import local from "./local";

let axios = axios1.create({});
import {
    Message,
    Loading
} from 'element-ui';

const url = window.location.href;
const urlParams = getUrlParams(url);
console.log(url, urlParams, '页面地址和参数');

function getUrlParams(url) {
    let params = {};
    const paramStr = url.split("?")[1];
    if (paramStr) {
        const paramArr = paramStr.split("&");
        for (const param of paramArr) {
            const [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        }
    }
    return params;
}

function requestToken(urlParams) {
    // console.log('requestToken', urlParams)
    // if (!isEmpty(urlParams)) {
    // 	let timestamp = 1000 * 5000 + new Date().getTime();
    // 	let obj = {
    // 		ticket: urlParams.token,
    // 		expireTimestamp: timestamp,
    // 		expire: 5000
    // 	};
    // 	local.set("xy_token", obj);
    // 	A(1000)
    // 	return obj.ticket
    // } else {

    // }
    let sendTokenHeaders = {};
    if (!isEmpty(urlParams)) sendTokenHeaders = {['xiaoyou-token']: urlParams.token}
    axios.post('/api/user/login-ticket', {headers: sendTokenHeaders}).then(res => {
        let data = res.data.data;
        let rt = res.data;
        console.log("获取ticket", res);
        if (rt.result) {
            data.expireTimestamp = data.expire * 1000 + (new Date().getTime());//过期时间戳
            local.set('xy_token', data)
            needToken = false;
            A(10000);
            return data.ticket
        } else {
            if (rt.errorCode === 901 || rt.errorCode === -201) {
                // A(10000);
                console.log("需要重新登录");
                location.href = "/erp/#/";
            } else {
                A(5000);
            }
        }
    }).catch(err => {
        // console.log(err, '输出错误');
        A(5000);
    });
}

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

let timeHandle = null;
let A = function (time = 5000, urlParams = {}) {
    if (timeHandle != null) {
        clearTimeout(timeHandle)
    }
    timeHandle = setTimeout(() => {
        if (needToken) {
            requestToken(urlParams)
        } else {
            A(10000)
            // console.log('输出错误2');
        }
    }, time);
}

let needToken = true;

function getToken1() {
    let tokenInfo = local.get('xy_token');
    let now = new Date().getTime();
    if (tokenInfo && now < tokenInfo.expireTimestamp) {//有token且没过期
        // console.log('拥有ticket', tokenInfo)
        return tokenInfo.ticket;
    } else {
        needToken = true;
        return ''
    }

}

axios.interceptors.request.use(config => {
    let token = getToken1();
    // 统一带令牌 ( 后端对接口进行了安全验证 如果不带令牌 所有的ajax都发不通 )
    // console.log(token, '是否token')
    // console.log(config.headers, "===============")
    if (config.headers == null) {
        config.headers['Content-Type'] = "application/json;charset=UTF-8"
    }
    if (token) { // Authorization 授权证书
        config.headers['xiaoyou-token'] = token
        // config.headers.Authorization = token; // 所有的请求 都要携带令牌 才能正常
    }
    return config
})

let swei = {};
swei.common = {};
swei.requestToken = A;
swei.downFile = function (url, fileName) {
    const x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = "blob";
    x.onload = function () {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
    };
    x.send();

}
swei.timeBetweenString = function (_now) {
    let second = (new Date().getTime() - _now);
    var time = parseInt(second / 1000);

    if (time < 60) return second + "秒";
    var time2 = 0;
    var str1 = "";
    var str2 = "";
    var str = "";
    str = "刚刚";
    if (time >= 60) {
        if ((time % 60) > 0) {
            str2 = (time % 60) + '秒';
        }
        time = Math.floor(time / 60);
        if (time > 0)
            str1 = time + '分钟';
        if (time >= 60) {
            if ((time % 60) > 0) {
                str2 = (time % 60) + '分钟';
            }
            time = Math.floor(time / 60);
            if (time > 0)
                str1 = time + '小时';

            if (time > 24) {
                if ((time % 24) > 0) {
                    str2 = (time % 24) + '时';
                }
                time = Math.floor(time / 24);
                if (time > 0)
                    str1 = time + '天';
            }
        }
    }
    return str1 + str2;
}
//接收参数处理
swei.re = function (paras, _url) {
    if ($.mobile) {
        // url=$('.ui-page-active').data('url');
    }
    // console.log("search>>"+url);
    var uri = url.substring(url.indexOf("?") + 1, url.length) || _url;
    uri = uri.split("#")[0];
    var paraString = uri.split("&");
    var paraObj = {};
    let j;
    for (var i = 0; j = paraString[i]; i++) {
        paraObj[j.substring(0, j.indexOf("=")).toLowerCase()] = j.substring(j
            .indexOf("=") + 1, j.length);
    }
    var returnValue = paraObj[paras.toLowerCase()];
    if (typeof (returnValue) == "undefined") {
        return "";
    } else {
        return returnValue;
    }
}
//方法兼容性扩展
swei.common.ajax = function (_url, _config) {
    let conf = _config || {};
    conf.async = conf.async || false;
    conf.method = conf.method || "get";
    conf.data = conf.data || {};
    conf.headers = conf.headers || {};
    conf.success = _config.success || function () {
    };
    conf.error = _config.error || function (data) {
        Message({
            message: data.message,
            type: 'error'
        })
    };
    conf.complete = _config.complete || function () {
    };
    if (conf.async === true) { //同步处理
        console.log("sd");
    } else { //异步处理
        if ("get" === conf.method.toLowerCase()) {
            axios.get(_url, {
                params: conf.data, headers: conf.headers
            }).then((res) => {
                if (res.data.result != null) {
                    if (res.data.result) {
                        conf.success(res.data.data, res.data);
                    } else {
                        conf.error(res.data, res);
                    }
                } else {
                    if (res.data && (res.data.errorCode === 901 || res.data.errorCode === -201)) {
                        // A(10000);
                        console.log("需要重新登录");
                        location.href = "/erp/#/";
                    }else if (res.data.error == 0) {
                        conf.success(res.data.data, res.data);
                    } else {
                        conf.error(res.data, res);
                    }
                }
            }).catch((xhr) => {
                let err=xhr.response;
                if(!err)return;
                if (err.data && (err.data.errorCode === 901 || err.data.errorCode === -201)) {
                    // A(10000);
                    console.log("需要重新登录");
                    location.href = "/erp/#/";
                } else {
                    console.log('ajax error console:',err);
                    // conf.error(err);

                }
            })
        } else {
            console.log(conf.headers)
            if (typeof (conf.data) == typeof ("")) {
                conf.headers['Content-Type'] = "application/json;charset=UTF-8"
            }
            axios.post(_url, conf.data, {headers: conf.headers}
            ).then((res) => {
                if (res.data.result != null) {
                    if (res.data.result) {
                        conf.success(res.data.data, res.data);
                    } else {
                        conf.error(res.data, res);
                    }
                } else {
                    if (res.data.error == 0) {
                        conf.success(res.data.data, res.data);
                    } else {
                        conf.error(res.data, res);
                    }
                }
            }).catch((err) => {
                if (err.data && (err.data.errorCode === 901 || err.data.errorCode === -201)) {
                    // A(10000);
                    console.log('ajax GET error:',err);
                    console.log("需要重新登录");
                    location.href = "/erp/#/";
                } else {
                    console.log(err)
                    conf.error(err);
                }
            })
        }
        // } else {
        // 	axios.post(_url, {
        // 		params: conf.data
        // 	}).then((res) => {
        // 		console.log("res=", res)
        // 		if (res.data.result) {
        // 			conf.success(res.data.data, res.data);
        // 		} else {
        // 			conf.error(res.data, res);
        // 		}
        // 	}).catch((err) => {
        // 		console.log(err)
        // 		conf.error(err);
        // 	})
        // }


    }
}
swei.local = local;
// 加密使用：window.btoa(unescape(encodeURIComponent('我是一段需要处理的字符')))
// 解密使用：decodeURIComponent(escape(window.atob('5oiR5piv5LiA5q616ZyA6KaB5aSE55CG55qE5a2X56ym')))
swei.string = {};
swei.string.encode = function (str) {
    return window.btoa(unescape(encodeURIComponent(str)))
}
swei.string.decode = function (str) {
    return decodeURIComponent(escape(window.atob(str)))
}


A(1000, urlParams);

// 暴露出去
export default swei
