import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//重置样式
import '@/assets/css/normalize.css';
import '@/assets/css/reset.css';
// 引入element-ui
import ElementUI , {Table} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入iconfont
import '@/assets/font/iconfont.css'

import local from '@/utils/local'
// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.$local = local;
Vue.prototype.$bus = new Vue();

import VueUeditorWrap from 'vue-ueditor-wrap'; // ES6 Module
//注册为全局组件
Vue.component('vue-ueditor-wrap', VueUeditorWrap);


//引入amap
// import VueAMap from 'vue-amap';
//
// VueAMap.initAMapApiLoader({
//     key: '24307c8a47a5a1a9c68b55b4d8c02356',
//     plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
//         'AMap.MapType', 'AMap.PolyEditor', "AMap.Heatmap", "MarkerClusterer"
//     ],
//     v: '1.4.4'
// });
Vue.use(VueAMap);
import VueAMap from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: '24307c8a47a5a1a9c68b55b4d8c02356',
});


// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const fixElTableErr = (table) => {
    const oldResizeListener = table.methods.resizeListener;
    table.methods.resizeListener = function () {
        window.requestAnimationFrame(oldResizeListener.bind(this));
    };
};
// 在Vue.use之前执行此函数
fixElTableErr(Table);
// 将自动注册所有组件为全局组件
Vue.use(ElementUI,{size: 'mini'});


Vue.filter('currency', function (num) {
    num = (num + '').replace(/\$|\,/g, '');
    if (isNaN(num))
        num = "0";
    let sign = (num == (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100) + '';
    if (cents < 10)
        cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        num = num.substring(0, num.length - (4 * i + 3)) + ',' +
            num.substring(num.length - (4 * i + 3));
    return (((sign) ? '' : '-') + num + '.' + cents);
});
Date.prototype.format = function (format) {
    if (this == null || this.getTime() == 0) return "";
    var o = {
        "M+": this.getMonth() + 1, // month
        "d+": this.getDate(), // day
        "h+": this.getHours(), // hour
        "m+": this.getMinutes(), // minute
        "s+": this.getSeconds(), // second
        "q+": Math.floor((this.getMonth() + 3) / 3),
        "S": this.getMilliseconds()
    }

    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (this.getFullYear() + "")
            .substr(4 - RegExp.$1.length));
    }

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] :
                ("00" + o[k]).substr(("" + o[k]).length));
        }
    }
    return format;
}
//时间过滤器
Vue.filter('date', function (val, _partten) {
    if (val == undefined || val == null || val == "") {
        return "";
    }
    var partten = _partten || "yyyy-MM-dd  hh:mm";
    return new Date(val).format(partten);
});
//时间过滤器
Vue.filter('dateTime', function (val, _partten) {
    if (val == undefined || val == null || val == "") {
        return "";
    }
    var partten = _partten || "yyyy-MM-dd  hh:mm:ss";
    return new Date(val).format(partten);
});
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
