import { render, staticRenderFns } from "./NavLeft.vue?vue&type=template&id=12aa40fa&scoped=true"
import script from "./NavLeft.vue?vue&type=script&lang=js"
export * from "./NavLeft.vue?vue&type=script&lang=js"
import style0 from "./NavLeft.vue?vue&type=style&index=0&id=12aa40fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12aa40fa",
  null
  
)

export default component.exports